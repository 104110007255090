import {CdkVirtualForOf, CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {DOCUMENT} from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  Inject,
  NgZone,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import {animationFrameScheduler, merge, Observable} from 'rxjs';
import {auditTime, startWith} from 'rxjs/operators';

import {DeviceService} from '../../device/device.service';
import {ResponsiveService} from '../../responsive/responsive.service';
import {ScrollDirection, ScrollEvent} from '../scroll-event';
import {ScrollableBaseComponent} from '../scrollable-base.component';

/**
 * Wrapper for Angular's CDK 'CdkFixedSizeVirtualScroll'.
 * Hides scrollbars and draws a custom rail.
 */
// eslint-disable-next-line prefer-none-view-encapsulation
@Component({
  selector: 'tl-virtual-scroll',
  templateUrl: './virtual-scroll.component.html',
  styleUrls: ['./virtual-scroll.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VirtualScrollComponent
  extends ScrollableBaseComponent
  implements AfterContentInit, OnDestroy
{
  @ContentChild(CdkVirtualScrollViewport)
  viewport: CdkVirtualScrollViewport;

  @ContentChild(CdkVirtualForOf)
  forOf: CdkVirtualForOf<any>;

  constructor(
    protected deviceService: DeviceService,
    @Inject(DOCUMENT) protected document: Document,
    protected elementRef: ElementRef,
    protected ngZone: NgZone,
    protected renderer: Renderer2,
    protected responsiveService: ResponsiveService,
  ) {
    super(deviceService, document, elementRef, ngZone, renderer, responsiveService);
  }

  ngAfterContentInit() {
    super.ngAfterContentInit();

    this.ngZone.runOutsideAngular(() =>
      Promise.resolve(undefined).then(() => {
        this.viewport
          .elementScrolled()
          .subscribe(() =>
            this.scrollChangeOutsideAngular.emit(this.buildScrollEvent()),
          );
      }),
    );
  }

  attachEvents(): void {
    super.attachEvents();

    let updateObservable: Observable<any | null> = this.viewport.elementScrolled();

    if (this.forOf) {
      updateObservable = merge(updateObservable, this.forOf.dataStream);
    }

    this.listeners.push(
      updateObservable
        .pipe(startWith(null), auditTime(0, animationFrameScheduler))
        .subscribe(() => this.updateRail()),
    );

    this.listeners.push(
      this.rail.scrollTopUpdater
        .pipe(auditTime(0, animationFrameScheduler))
        .subscribe(scrollTop => this.onRailDrag(scrollTop)),
    );
  }

  hasScroll(): boolean {
    if (!this.viewport) {
      return false;
    }

    return (
      this.viewport.getViewportSize() <
      this.viewport.getElementRef().nativeElement.scrollHeight
    );
  }

  setScrollPosition(scrollTop): void {
    if (this.viewport) {
      this.viewport.scrollToOffset(scrollTop);

      this.ngZone.runOutsideAngular(() => {
        this.updateRail();
        this.scrollChangeOutsideAngular.emit(this.buildScrollEvent());
      });
    }
  }

  /**
   * This may be inaccurate at times when the scroll is moving fast and the
   * range ofthe virtual scroll changes.
   */
  getScrollTop(): number {
    return this.viewport ? this.viewport.measureScrollOffset('top') : 0;
  }

  /**
   * This may be inaccurate at times when the scroll is moving fast and the
   * range of the virtual scroll changes.
   */
  getScrollPercent(scrollDirection = ScrollDirection.DOWN): number {
    if (this.viewport) {
      if (this.viewport.getViewportSize() === 0) {
        // Check view for avoid errors on scrollableHeight
        this.viewport.checkViewportSize();
      }
      const scrollableHeight = Math.abs(
        this.viewport.getElementRef().nativeElement.scrollHeight -
          this.viewport.getViewportSize(),
      );

      const amountScrolled =
        scrollDirection === ScrollDirection.UP
          ? scrollableHeight - this.viewport.measureScrollOffset('top')
          : this.viewport.measureScrollOffset('top');

      return (amountScrolled / scrollableHeight) * 100;
    } else {
      return 0;
    }
  }

  protected onRailDrag(scrollTop: number): void {
    if (!this.viewport) {
      return;
    }

    this.viewport.scrollToOffset(scrollTop);
  }

  protected updateRail(): void {
    if (!this.rail || !this.viewport) {
      return;
    }

    this.rail.setConstraints(
      this.viewport.getViewportSize(),
      this.viewport.getElementRef().nativeElement.scrollHeight,
    );
    this.rail.setScrollTop(this.viewport.measureScrollOffset('top'));
  }

  private buildScrollEvent(): ScrollEvent {
    let scrollTop = this.getScrollTop();
    let scrollDirection =
      scrollTop < this.lastScrollTop ? ScrollDirection.UP : ScrollDirection.DOWN;
    this.lastScrollTop = scrollTop;
    return {
      scrollTop: scrollTop,
      scrollPercent: this.getScrollPercent(scrollDirection),
      element: this.viewport ? this.viewport.getElementRef() : null,
      scrollDirection: scrollDirection,
    };
  }
}
