<tl-full-height *ngIf="fullscreen; else modalContent">
  <ng-template [ngTemplateOutlet]="modalContent"></ng-template>
</tl-full-height>

<ng-template #modalContent>
  <div
    class="dialog-wrapper"
    [class.titled]="!!title"
    [class.content-justify-center]="justifyCenterDesktop"
    [class.padded]="closeButton && !headerImage && !image"
    [class.fullscreen]="fullscreen"
    [class.headerImage]="!!headerImage"
    [class.dialog-wrapper--with-buttons]="showButtons"
    [class.force-wide]="forceWide">
    <div *ngIf="!!header" class="title dark-default dialog-header">
      <tl-translatable-text [text]="header"></tl-translatable-text>
    </div>
    <div *ngIf="closeButton" class="closer" (click)="leaveModal('cancel')">
      &times;
    </div>
    <tl-scrollable
      class="wrapper-dialog"
      [class.wrapper-dialog--column]="layout === modalDialogLayout.VERTICAL">
      <div *ngIf="!!headerImage || !!image" class="wrapper-content-image">
        <div *ngIf="!!headerImage" class="header-image">
          <img
            [src]="headerImage"
            [ngClass]="{
              'round-image': roundImage,
              'gray-filter': showImageWithGrayFilter
            }" />
        </div>
        <div *ngIf="!!image" class="image">
          <img
            [src]="image"
            [style.width.px]="imageWidth"
            [ngClass]="{
              'round-image': roundImage,
              'gray-filter': showImageWithGrayFilter
            }" />
        </div>
      </div>

      <div class="wrapper-content-body">
        <div *ngIf="!!title" class="title dark-default">
          <tl-translatable-text [text]="title"></tl-translatable-text>
        </div>

        <div
          class="dialog-content"
          *ngIf="showContent"
          [class.container-padding]="!disableContentPadding"
          [class.inline]="inlineMessages"
          [class.wide-container]="wideContentPadding">
          <tl-translatable-text
            *ngIf="subtitle"
            class="message subtitle"
            [text]="subtitle">
          </tl-translatable-text>

          <ng-template
            *ngIf="templateMessage; else defaultMessage"
            [ngTemplateOutlet]="templateMessage">
          </ng-template>
          <ng-template #defaultMessage>
            <tl-translatable-text
              *ngFor="let desc of messages"
              class="message"
              [class.message--small]="smallText"
              [class.message--compact]="compactText"
              [text]="desc">
            </tl-translatable-text>
          </ng-template>

          <tl-translatable-text
            *ngFor="let footNote of footNotes"
            class="dialog-content__footnote"
            [text]="footNote">
          </tl-translatable-text>

          <ng-content></ng-content>
        </div>
        <ng-container *ngIf="allowAvoidModal">
          <tl-form-row
            [name]="allowAvoidText"
            [largeLabel]="true"
            class="tl-modal-dialog__switch">
            <tl-switch [formControl]="avoidModalFC"></tl-switch>
          </tl-form-row>
        </ng-container>
      </div>
    </tl-scrollable>

    <div
      *ngIf="showButtons"
      class="dialog-buttons"
      [class.loading]="loading || (loadWhenAccepting && processingLoad)"
      [class.dialog-buttons-padded]="buttonsPadded"
      [class.footered]="footer"
      [class.danger-accept]="danger && !invertBtnColors"
      [class.danger-cancel]="danger && invertBtnColors">
      <ng-container *ngIf="okButton">
        <ng-template
          *ngIf="accept | isTemplate; else defaultAccept"
          [ngTemplateOutlet]="accept"
          [ngTemplateOutletContext]="okOutletContext">
        </ng-template>
        <ng-template #defaultAccept>
          <button
            class="btn"
            type="button"
            id="modal-accept"
            [disabled]="disableAccept | wrapInObservable | async"
            [ngClass]="{
              'btn-mobile-round': isMobile && !fullscreen,
              'btn-mobile-accept': isMobile && !invertBtnColors,
              'btn-mobile-cancel': isMobile && invertBtnColors,
              'btn-mobile-fullwidth': isMobile && fullscreen,
              'btn-type-4': !isMobile && !invertBtnColors,
              'btn-type-3': !isMobile && invertBtnColors,
              'btn-hide-border': hiddeBorderCancel,
              accept: !isMobile,
              danger: danger
            }"
            [style.order]="!invertBtnColors ? 1 : 2"
            (click)="leaveModal('accept')">
            <tl-translatable-text
              [text]="accept | wrapInObservable | async"></tl-translatable-text>
          </button>
        </ng-template>
      </ng-container>

      <ng-container *ngIf="cancelButton">
        <ng-template
          *ngIf="cancel | isTemplate; else defaultCancel"
          [ngTemplateOutlet]="cancel"
          [ngTemplateOutletContext]="cancelOutletContext">
        </ng-template>
        <ng-template #defaultCancel>
          <button
            *ngIf="cancelButton"
            id="modal-cancel"
            type="button"
            class="btn"
            [ngClass]="{
              'btn-mobile-round': isMobile && !fullscreen,
              'btn-mobile-cancel': isMobile && !invertBtnColors,
              'btn-mobile-accept': isMobile && invertBtnColors,
              'btn-mobile-fullwidth': isMobile && fullscreen,
              'btn-type-3': !isMobile && !invertBtnColors,
              'btn-type-4': !isMobile && invertBtnColors,
              'cancel-danger': cancelDanger,
              'btn-hide-border': hiddeBorderCancel,
              cancel: !isMobile
            }"
            [style.order]="!invertBtnColors ? 2 : 1"
            (click)="leaveModal('cancel')">
            <tl-translatable-text [text]="cancel"></tl-translatable-text>
          </button>
        </ng-template>
      </ng-container>

      <div class="loader">
        <i class="icon-spin6-icon tl-icon-spin"></i>
      </div>
      <button
        *ngIf="!isMobile && !!backButton && type !== 'buttonless'"
        class="btn left-side-btn back-btn"
        type="button"
        id="modal-back"
        (click)="backAction()">
        <tl-translatable-text [text]="{key: 'global.back'}"></tl-translatable-text>
      </button>
    </div>
    <div *ngIf="footer" class="dialog-footer">
      <ng-container *ngIf="footer | isTemplate; else basicFooter">
        <ng-template [ngTemplateOutlet]="footer"></ng-template>
      </ng-container>
      <ng-template #basicFooter>
        <tl-translatable-text [text]="footer"></tl-translatable-text>
      </ng-template>
    </div>
  </div>
</ng-template>
