export function decodeJWT(token) {
  const parts = token.split('.');

  if (parts.length !== 3) {
    throw new Error('The format of JWT is not valid.');
  }

  const [header, payload, signature] = parts;

  const decodedHeader = base64UrlDecode(header);
  const decodedPayload = base64UrlDecode(payload);

  return {
    header: decodedHeader,
    payload: decodedPayload,
    signature: signature,
  };
}

function base64UrlDecode(base64Url) {
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const decodedData = atob(base64);
  return JSON.parse(decodedData) as any;
}
