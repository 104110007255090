import {ChangeDetectorRef, OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {UriMapper} from '../uri/uri-mapper';
import {catchError, filter, first} from 'rxjs/operators';
import {AsyncPipe} from '@angular/common';
import {of} from 'rxjs';

@Pipe({
  name: 'translatableUrl',
})
export class TranslatableUrlPipe implements PipeTransform, OnDestroy {
  private asyncPipe: AsyncPipe;

  constructor(protected cdr: ChangeDetectorRef, private uriMapper: UriMapper) {
    this.asyncPipe = new AsyncPipe(cdr);
  }

  transform(value: string): string {
    const regexHasLink = /href\s*=\s*["'](.*?)["']/;
    if (regexHasLink.test(value)) {
      const regexUrl = /href\s*=\s*["'](.*?)["']/gi;
      const provisionalMatches: Array<RegExpMatchArray> = Array.from(
        value.matchAll(regexUrl),
      );
      // This is to avoid having the same URL matched twice we causes problems later while replacing
      let matches: string[] = [];
      for (const provisionalMatch of provisionalMatches) {
        if (!matches.includes(provisionalMatch[1])) {
          matches.push(provisionalMatch[1]);
        }
      }
      // iterate urls
      for (const url of matches) {
        if (this.uriMapper.isInternalRoute(url)) {
          const internalUrl$ = this.uriMapper.map(url).pipe(
            first(),
            filter(uri => !!uri),
            catchError(() => of(void 0)),
          );
          const internalUrl = this.asyncPipe.transform(internalUrl$);
          if (internalUrl) {
            // Add tag with original url for avoid reload page on click
            // (TranslatableTextComponent)
            const replaceUrl = `${internalUrl}" data-href="${url}`;
            value = value.replaceAll(url, replaceUrl);
          }
        }
      }
    }
    return value;
  }

  ngOnDestroy(): void {
    this.asyncPipe.ngOnDestroy();
  }
}
