import {DOCUMENT} from '@angular/common';
import {
  ApplicationRef,
  ChangeDetectorRef,
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Injector,
  Input,
  NgZone,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';
import {NgbTooltip, NgbTooltipConfig} from '@ng-bootstrap/ng-bootstrap';
import {DeviceService} from '../device/device.service';
import {Logger} from '../logger/logger';
import {DeviceOs} from '../device/deviceOs';

@Directive({selector: '[tlClipboardCopy]', exportAs: 'tlClipboardCopy'})
export class ClipboardCopyDirective extends NgbTooltip {
  @Input('tlClipboardCopy')
  set tooltipText(tooltip: string) {
    this.ngbTooltip = tooltip;
  }

  triggers = 'manual';

  constructor(
    appRef: ApplicationRef,
    cdr: ChangeDetectorRef,
    protected elementRef: ElementRef,
    protected renderer: Renderer2,
    injector: Injector,
    componentFactoryResolver: ComponentFactoryResolver,
    viewContainerRef: ViewContainerRef,
    config: NgbTooltipConfig,
    protected ngZone: NgZone,
    @Inject(DOCUMENT) protected document: any,
    @Inject('window') protected window: Window,
    private deviceService: DeviceService,
    private logger: Logger,
  ) {
    super(
      elementRef,
      renderer,
      injector,
      componentFactoryResolver,
      viewContainerRef,
      config,
      ngZone,
      document,
      cdr,
      appRef,
    );

    this.tooltipClass = 'copy-tooltip';
    this.container = 'body';
    this.placement = ['top', 'top-left', 'top-right'];
  }
  @HostListener('click')
  copyOnClick() {
    if (typeof this.elementRef.nativeElement.select === 'function') {
      this.executeCopy(this.elementRef.nativeElement);
    } else {
      const element = document.createElement('textarea');
      element.value = this.elementRef.nativeElement.textContent;
      document.body.appendChild(element);
      this.executeCopy(element);
      element.remove();
    }
  }

  displayTooltip(): void {
    if (this.ngbTooltip) {
      this.open();
      setTimeout(() => this.close(), 2000);
    }
  }

  private executeCopy(element): void {
    if (this.useClipboardApi()) {
      if (!this.window.isSecureContext) {
        this.logger.error('Clipboard API only work over https');
        return;
      }
      this.window.navigator.clipboard.writeText(element.value).then(
        () => {},
        err => {
          this.logger.error('Error when copy text using Clipboard API', err);
        },
      );
    } else {
      element.select();

      this.document.execCommand('copy');

      (element as HTMLInputElement).setSelectionRange(0, 0);
    }
    this.displayTooltip();
  }

  private useClipboardApi(): boolean {
    return !!(
      this.deviceService.getOS() === DeviceOs.IOS && this.window.navigator.clipboard
    );
  }
}
