import {environment} from '~environments/environment';
import {fixPhoneNumber} from 'common';
import {CountryCode, parsePhoneNumber} from 'libphonenumber-js/max';

export function formatPhoneNumber(prefix: string, phone: string): string {
  if (!!prefix && !!phone) {
    const phoneToCheck = `${prefix}${phone}`;
    const defaultCountryCode =
      environment.locale.user.defaultPhoneCode.toUpperCase() as CountryCode;
    const phoneFixed = fixPhoneNumber(phoneToCheck, defaultCountryCode);
    const phoneNumber = parsePhoneNumber(phoneFixed, defaultCountryCode);
    if (phoneNumber.isValid()) {
      return `${prefix}${phone}`;
    } else {
      throw Error('Invalid phone number');
    }
  }
}
