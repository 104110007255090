import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Optional,
  Output,
  Renderer2,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {delay, filter, first, map, take, takeUntil, tap} from 'rxjs/operators';

import {TranslatableText} from '../../i18n/translatable-text';
import {ResponsiveService} from '../../responsive/responsive.service';

import {ModalDialogType} from './modal-dialog-type';
import {ModalDialogLayout} from './modal-dialog-layout';
import {MessageHookStorageService} from '../../message-hooks/message-hook-storage.service';
import {FormControl} from '@angular/forms';
import {Observable, of, Subject} from 'rxjs';
import {Destroyable} from '../../util/destroyable';

/**
 * Shows a modal dialog with a black backdrop.
 * There are 3 types of dialogs, all of the with a close button at the top
 * right:
 *
 *  - `ok` Dialog with one accept button
 *  - `ok_cancel` Dialog with accept and cancel buttons
 *  - `buttonless` Dialog with no buttons
 *  - `void` Dialog with no buttons and no close button
 *
 * Has an optional title wich will render at the top (further up than the
 * content can reach)
 *
 * Defines a text style class named 'dark-default' available to children.
 */
type Callback = (() => unknown) | (() => Observable<unknown>);

// eslint-disable-next-line prefer-none-view-encapsulation
@Component({
  selector: 'tl-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalDialogComponent implements OnChanges, OnInit {
  /**
   * - `ok` Dialog with one accept button and close 'x' button
   * - `ok_cancel` Dialog with accept and cancel buttons and close 'x' button
   * - `buttonless` Dialog with no buttons
   * - `ok_only` Dialog with one accept button and no close 'x' button
   * - `ok_cancel_only` Dialog with accept and cancel button and no close 'x
   * button
   * - `void` Dialog with no buttons and no close button
   *
   */
  @Input()
  type: ModalDialogType = 'buttonless';

  /**
   * - `normal` Normal dialog without styling
   * - `fullscreen` Styles are applied in order to fil the full window
   *
   */
  @Input()
  mode: 'normal' | 'fullscreen' = 'normal';

  @Input()
  layout: ModalDialogLayout;

  /**
   * Optional, image for the header.
   */
  @Input()
  header: string | TranslatableText;

  /**
   * Optional, image for the header.
   */
  @Input()
  headerImage: string;

  /**
   * Optional, image for the dialog.
   */
  @Input()
  image: string;

  /**
   * Optional, width image for the dialog.
   */
  @Input()
  imageWidth = 150;

  /**
   * Optional, show round image
   */
  @Input()
  roundImage = false;

  /**
   * Optional, show image with gray filter
   */
  @Input()
  showImageWithGrayFilter = false;
  /**
   * Optional, title for the dialog.
   */
  @Input()
  title: string | TranslatableText;
  /**
   * Optional, subtitle for the dialog.
   */
  @Input()
  subtitle: string | TranslatableText;

  /**
   * Optional, text for the accept button.
   */
  @Input('accept')
  accept: string | TranslatableText | TemplateRef<any> = {
    key: 'global.acceptButton',
  };

  /**
   * Optional, text for the cancel button.
   */
  @Input('cancel')
  cancel:
    | string
    | TranslatableText
    | TemplateRef<any>
    | Observable<TranslatableText> = {
    key: 'global.cancelButton',
  };

  /**
   * While true will show a loading spinner replacing the buttons.
   * If the type is void this has no effect.
   */
  @Input()
  loading = false;

  /**When true, buttons will show a loader instead of the buttons themselves
   * when the user has clicked the accept/cancel button if the callback returns an observable*/
  @Input()
  loadWhenAccepting = false;

  /**when loadWhenAccepting is enabled, it controls whether we're processing the callbacks.
   * Check loadWhenAccepting for more info
   */
  processingLoad = false;

  /**
   * Function to execute when the accept button is clicked and before
   * the dialog is closed.
   * If this function returns false the dialog wont close.
   *
   * If the supplied function uses `this` internally it must be bound, ex:
   * ```
   *  foo() {
   *    //do somthing with this
   *  }
   *
   *  this.foo = foo.bind(this)
   *  ```
   */
  @Input()
  acceptCallBack: Callback;

  /**
   * If wants to show back button on desktop
   *  ```
   */
  @Input()
  backButton: Callback;

  /**
   * Same as {acceptCallBack} but for the cancel and close buttons.
   */
  @Input()
  cancelCallBack: Callback;

  /**
   * Optional, template to be placed in the footer, after dialog buttons.
   */
  @Input()
  footer: TemplateRef<any> | TranslatableText;

  /**
   * Optional, animation class to be applied when opening the dialog.
   */
  @Input()
  classAnimationIn: string;

  /**
   * Optional, animation class to be applied when closing the dialog.
   */
  @Input()
  classAnimationOut: string;

  /**
   * When true closes this modal when a popstate event is fired.
   * Only works if this dialog is an active modal.
   */
  @Input()
  closeOnPopstate = false;

  /**
   * When true closes this modal when a router navigation happens.
   * Only works if this dialog is an active modal.
   */
  @Input()
  closeOnNavigation = false;

  /**
   * Optional, indicates if danger format should be applied.
   */
  @Input()
  danger: boolean;

  /**
   * Optional, indicates if danger format for the cancel button should be applied.
   */
  @Input()
  cancelDanger: boolean;

  /**
   * Optional, force to ser more width in dialog
   */
  @Input()
  forceWide: boolean;

  /**
   * Optional. This is intended to be used when no transclude content present.
   */
  @Input()
  set message(
    message:
      | TemplateRef<any>
      | string
      | TranslatableText
      | Array<string | TranslatableText>,
  ) {
    if (message instanceof TemplateRef) {
      this.templateMessage = message;
    } else {
      this.messages = Array.isArray(message) ? message : [message];
    }
  }
  templateMessage: TemplateRef<any>;
  messages: Array<string | TranslatableText>;

  /** Optional. to set small size 14px to messages */
  @Input()
  smallText = false;

  /** Optional. to set small size 14px to messages */
  @Input()
  compactText = false;

  /** Optional. when pass array of messages show like span (inlines) */
  @Input()
  inlineMessages = false;

  /**
   * Optional. This is intended to be used to print foot-note small text.
   */
  @Input()
  set footNote(
    footNote: string | TranslatableText | Array<string | TranslatableText>,
  ) {
    this.footNotes = Array.isArray(footNote) ? footNote : [footNote];
  }
  footNotes: Array<string | TranslatableText>;

  /**
   * Disable accept button
   */
  @Input()
  disableAccept: boolean | Observable<boolean> = false;

  /**
   * Optional, to unset padding in the container modal.
   */
  @Input()
  disableContentPadding = false;

  /**
   * Optional, to increasse padding in the container modal.
   */
  @Input()
  wideContentPadding = false;

  /**
   * Optional, hide border in cancel button
   */
  @Input()
  hiddeBorderCancel = false;

  /**
   * Break long words, useful for dialogs with dynamic content that can get very long
   * e.g. club names without spaces.
   */
  @Input()
  breakWords = false;

  @Output()
  closeModal = new EventEmitter<any>();

  @Output()
  dismissModal = new EventEmitter<any>();

  okButton = false;

  cancelButton = false;

  closeButton = false;

  // To avoid spaces when using the ModalDialog from the service
  // and nothing is to be passed in the ng-content
  showContent = true;

  /**
   * When it is true, the default cancel button is replaced by the accept button.
   * This is only visual and is for marketing purposes.
   */
  @Input()
  invertBtnColors: boolean;

  @Input()
  buttonsPadded: boolean;

  @Input()
  justifyCenterDesktop: boolean;

  /**
   * When is enabled the modal show a switch button to avoid the modal in the future
   */
  @Input()
  allowAvoidModal = false;

  /**
   * Key stored on localStorage -> popupStates to mark do not show the modal in the future
   */
  @Input()
  avoidModalKey: string;

  /**
   * text to show in switch label
   */
  @Input()
  allowAvoidText: string | TranslatableText | TemplateRef<any> = {
    key: 'global.dontAskAgain',
  };

  avoidModalFC: FormControl = new FormControl(false);

  @Input()
  valueOnAccept: any = 'success';

  @Destroyable()
  private destroySubject = new Subject<void>();

  get isMobile(): boolean {
    return this.responsiveService.isMobile();
  }

  public get modalDialogLayout() {
    return ModalDialogLayout;
  }

  get fullscreen(): boolean {
    return this.mode === 'fullscreen';
  }

  get okOutletContext(): {$implicit: (a: string) => void} {
    return {$implicit: () => this.leaveModal('accept')};
  }

  get cancelOutletContext(): {$implicit: (a: string) => void} {
    return {$implicit: () => this.leaveModal('cancel')};
  }

  get showButtons(): boolean {
    return this.okButton || this.cancelButton || !!this.backButton;
  }

  constructor(
    @Optional() public activeModal: NgbActiveModal,
    private elementRef: ElementRef,
    private ngZone: NgZone,
    private renderer: Renderer2,
    private responsiveService: ResponsiveService,
    @Inject('window') protected window: Window,
    private router: Router,
    private messageHookStorageService: MessageHookStorageService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('type')) {
      this.setOptions();
    }
  }

  ngOnInit(): void {
    if (this.layout === undefined || this.layout === null) {
      const isDesktop = this.responsiveService.isDesktop();
      this.layout = isDesktop
        ? ModalDialogLayout.HORIZONTAL
        : ModalDialogLayout.VERTICAL;
    }

    /**
     * Animates the modal when opening if classAnimationIn is specified
     */
    if (this.classAnimationIn) {
      this.ngZone.onStable
        .pipe(
          first(),
          // fix safari open wrong height calculation
          delay(0),
        )
        .subscribe(() =>
          this.renderer.addClass(
            this.elementRef.nativeElement,
            this.classAnimationIn,
          ),
        );
    }

    if (this.closeOnPopstate && this.activeModal) {
      const handler = () => {
        this.activeModal.dismiss();
        this.window.removeEventListener('popstate', handler);
      };

      this.window.addEventListener('popstate', handler);
    }

    if (this.closeOnNavigation && this.activeModal) {
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          first(),
        )
        .subscribe(() => this.activeModal.dismiss());
    }

    this.setOptions();
  }

  /**
   * Animates the modal before closing if classAnimationOut is specified
   *
   * @param leaveAction whether it should go to accept or cancel
   */
  leaveModal(leaveAction: 'accept' | 'cancel'): void {
    if (this.classAnimationOut) {
      this.renderer.addClass(this.elementRef.nativeElement, this.classAnimationOut);
      this.renderer.listen(this.elementRef.nativeElement, 'animationend', () => {
        if (leaveAction === 'accept') {
          this.acceptAction();
        } else {
          this.cancelAction();
        }
      });
    } else if (leaveAction === 'accept') {
      this.acceptAction();
    } else {
      this.cancelAction();
    }
  }

  /**
   * Closes the dialog.
   */
  close(result?: any) {
    this.closeModal.emit(result);
    if (this.activeModal) {
      this.activeModal.close(result);
    }
  }

  /**
   * Dismisses the dialog.
   */
  dismiss(reason?: any) {
    this.dismissModal.emit(reason);
    if (this.activeModal) {
      this.activeModal.dismiss(reason);
    }
  }

  /**
   * Executed when the back buttons are clicked, runs the callback
   * never close the dialog.
   */
  public backAction() {
    this.processCallback(this.backButton).subscribe();
  }

  /**
   * Executed when the accept button is clicked, runs the callback (if any) and
   * closes the dialog by default.
   */
  private acceptAction() {
    if (
      this.allowAvoidModal &&
      !!this.avoidModalFC.value &&
      !this.messageHookStorageService.exists(this.avoidModalKey)
    ) {
      // Update the preference on localStorage
      this.messageHookStorageService.save(this.avoidModalKey);
    }
    this.processCallback(this.acceptCallBack).subscribe(result => {
      if (result) {
        this.close(this.valueOnAccept);
      }
    });
  }

  /**
   * Executed when the cancel or close buttons are clicked, runs the callback
   * (if any) and closes the dialog by default.
   */
  private cancelAction() {
    this.processCallback(this.cancelCallBack).subscribe(result => {
      if (result) {
        this.dismiss();
      }
    });
  }

  /**@returns true if the action can proceed (i.e: the callback didn't return false) */
  private processCallback(callback?: Callback): Observable<boolean> {
    this.processingLoad = this.loadWhenAccepting;
    let callbackResult: ReturnType<Callback>;
    if (callback) {
      try {
        callbackResult = callback();
      } catch (e) {
        callbackResult = false;
        throw e;
      }
    } else {
      callbackResult = true;
    }
    const observable: Observable<unknown> =
      callbackResult instanceof Observable
        ? callbackResult
        : of(callbackResult ?? null);
    return observable.pipe(
      take(1),
      tap(() => (this.processingLoad = false)),
      map(val => val !== false),
      takeUntil(this.destroySubject),
    );
  }

  private setOptions() {
    switch (this.type) {
      case 'ok':
        this.okButton = true;
        this.cancelButton = false;
        this.closeButton = true;
        break;
      case 'ok_cancel':
        this.okButton = true;
        this.cancelButton = true;
        this.closeButton = true;
        break;
      case 'buttonless':
        this.okButton = false;
        this.cancelButton = false;
        this.closeButton = true;
        break;
      case 'ok_only':
        this.okButton = true;
        this.cancelButton = false;
        this.closeButton = false;
        break;
      case 'ok_cancel_only':
        this.okButton = true;
        this.cancelButton = true;
        this.closeButton = false;
        break;
      case 'void':
        this.okButton = false;
        this.cancelButton = false;
        this.closeButton = false;
        break;
    }
  }
}
