import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {
  CurrencyModule,
  I18nModule,
  ModalDialogModule,
  SanitizerModule,
  TranslatableTextModule,
  URI_MAP_CONFIG,
  UriConfig,
} from 'common';
import {environment} from '~environments/environment';

import {LimitReachedModalComponent} from './limit-reached-modal.component';
const uriMaps: UriConfig = [
  {
    expense_exclusion: [
      {
        originUrl: environment.internalUrls.selfExclusion,
        destinationPlatformUrl: {
          desktop: `/${environment.locale.routes.selfExclusion.path}`,
          mobile: `/m/${environment.locale.routes.selfExclusion.path}`,
        },
      },
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    ModalDialogModule,
    I18nModule,
    CurrencyModule,
    SanitizerModule,
    TranslatableTextModule,
  ],
  exports: [LimitReachedModalComponent],
  declarations: [LimitReachedModalComponent],
  providers: [
    {
      provide: URI_MAP_CONFIG,
      useValue: uriMaps,
      multi: true,
    },
  ],
})
export class LimitReachedModalModule {}
