import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import Cropper from 'cropperjs';
import {ResponsiveService} from '../../responsive/responsive.service';

// eslint-disable-next-line prefer-none-view-encapsulation
@Component({
  selector: 'tl-img-crop',
  templateUrl: './img-crop.component.html',
  styleUrls: ['./img-crop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImgCropComponent implements OnChanges {
  @ViewChild('image', {read: ElementRef, static: true})
  container: ElementRef;

  @Input('image')
  img: string;

  private options: Cropper.Options = {
    aspectRatio: 1,
    center: true,
    scalable: true,
    background: false,
  };

  private readonly MOBILE_CROPPER_OPTIONS: Cropper.Options = {
    minContainerHeight: 200,
    minContainerWidth: 200,
    zoomable: true,
    viewMode: 3,
    dragMode: 'move',
    autoCrop: true,
    autoCropArea: 0.95,
    cropBoxMovable: true,
    cropBoxResizable: true,
  };

  private readonly DESKTOP_CROPPER_OPTIONS: Cropper.Options = {
    minContainerHeight: 300,
    minContainerWidth: 300,
    viewMode: 2,
    dragMode: 'crop',
    zoomable: false,
  };

  private cropper: Cropper;

  @Input('options')
  set customOptions(options: Cropper.Options) {
    this.options = Object.assign(this.options, options);
  }

  constructor(private responsiveService: ResponsiveService) {
    this.options = {
      ...this.options,
      ...(this.responsiveService.isMobile()
        ? this.MOBILE_CROPPER_OPTIONS
        : this.DESKTOP_CROPPER_OPTIONS),
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.cropper && changes.hasOwnProperty('options')) {
      this.generateCropper();
    }
  }

  generateCropper(): void {
    if (this.cropper) {
      this.cropper.clear();
      this.cropper.destroy();
    }
    this.cropper = new Cropper(this.container.nativeElement, this.options);
  }

  getCroppedImage(width = 512, height = 512): string {
    return this.cropper.getCroppedCanvas({width: width, height: height}).toDataURL();
  }
}
