import {Injectable} from '@angular/core';
import {filter, from, Observable, toArray} from 'rxjs';
import {first, map, switchMap} from 'rxjs/operators';

import {GenericResult} from './generic-result';
import {ResultsDao} from './results.dao';
import {State} from '../../../states/data/state';
import {StateAppDataService} from '../../../states/data/state-app-data.service';
import {GameMetadataService} from '../../game-metadata/data/game-metadata.service';
import {GameMetadata} from '../../game-metadata/data/game-metadata';
import {ResultsListService} from './results-list.service';
import {ResultsService} from './results.service';
import {PaginationData} from 'common';

@Injectable()
export class ResultsListByStateService extends ResultsListService {
  state: State;

  constructor(
    protected resultsDao: ResultsDao,
    protected resultsService: ResultsService,
    private stateAppDataService: StateAppDataService,
    private gameMetadataService: GameMetadataService,
  ) {
    super(resultsDao, resultsService);
    this.getData().subscribe(data => (this.paginationIndex = data.length));
    this.stateAppDataService.getData().subscribe((state: State) => {
      this.state = state;
    });
  }

  getData(): Observable<Array<GenericResult>> {
    if (!this.filter || this.filter.id === 'all') {
      return this.stateAppDataService.getData().pipe(
        filter((state: State) => !!state),
        switchMap((state: State) =>
          super.getData().pipe(
            switchMap(results =>
              from(results).pipe(
                switchMap((result: GenericResult) =>
                  this.gameMetadataService.getGameMetadata(result.gameId).pipe(
                    first(),
                    map((metadata: GameMetadata) => ({
                      result: result,
                      metadata: metadata,
                    })),
                  ),
                ),
                filter(
                  ({
                    result,
                    metadata,
                  }: {
                    result: GenericResult;
                    metadata: GameMetadata;
                  }) =>
                    !!metadata.visibleOnStates.find(
                      (code: string) => code === state.code,
                    ),
                ),
                map(
                  ({
                    result,
                    metadata,
                  }: {
                    result: GenericResult;
                    metadata: GameMetadata;
                  }) => result,
                ),
                toArray(),
              ),
            ),
          ),
        ),
      );
    }
    return super.getData();
  }

  protected moreResults(): Observable<PaginationData<GenericResult>> {
    return this.resultsDao.moreResults(
      this.paginationIndex,
      this.filter ? this.filter.gameIds : null,
      this.state,
    );
  }

  protected onConstructActions(): void {}
}
