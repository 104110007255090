import {ModuleWithProviders, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {RequestMediumPrizeSignatureInterceptor} from './request-medium-prize-signature.interceptor';
import {environment} from '~environments/environment';
import {SignatureModalModule} from '../signature-modal/signature-modal.module';
import {ModalHelperService, ROUTES} from 'common';
import {Router} from '@angular/router';

@NgModule({})
export class SignatureInterceptorModule {
  static isModuleEnabled(): boolean {
    return environment.features.prizes.enableSignature;
  }

  static withInterceptors(): ModuleWithProviders<SignatureModalModule> {
    return {
      ngModule: SignatureModalModule,
      providers: [
        ...[
          SignatureInterceptorModule.isModuleEnabled()
            ? {
                provide: HTTP_INTERCEPTORS,
                useClass: RequestMediumPrizeSignatureInterceptor,
                multi: true,
                deps: [ModalHelperService, Router, ROUTES],
              }
            : [],
        ],
      ],
    };
  }
}
