import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';

import {LocaleService} from '../../i18n/locale.service';
import {BigPrize} from '../big-prize';
import {BigPrizeButtonMetadata} from '../big-prize-button-metadata';

@Component({
  selector: 'tl-big-prize',
  templateUrl: './big-prize.component.html',
  styleUrls: ['./big-prize.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BigPrizeComponent {
  @Input()
  bigPrize: BigPrize | null;

  get trophyImage(): string {
    const locale = this.localeService.getLocale().split('-')[0];
    return `assets/img/big-prize/trophy-${locale}.svg`;
  }

  constructor(private localeService: LocaleService) {}

  onClick(btn: BigPrizeButtonMetadata) {
    this.bigPrize = null;
    btn.onClick();
  }
}
