import {environment} from '../environments/environment';
import {GameMetadataService} from './games/game-metadata/data/game-metadata.service';
import {UpcomingGamesGroupsProcessDataService} from './games/upcoming/data/upcoming-games-groups-process-data.service';
import {UpcomingGamesGroupsProcessPlayableService} from './games/upcoming/data/upcoming-games-groups-process-playable.service';
import {UpcomingGamesProcessDataService} from './games/upcoming/data/upcoming-games-process-data.service';
import {UpcomingGamesProcessStatesService} from './games/upcoming/data/upcoming-games-process-states.service';
import {BannerProcessDataService} from './banner/data/banner-process-data.service';
import {BannerProcessStatesService} from './banner/data/banner-process-states.service';
import {UpcomingGamesService} from './games/upcoming/data/upcoming-games.service';
import {ResultFiltersViewProcessDataService} from './games/results/widget/result-filters-view-process-data.service';
import {FilterViewProcessStatesService} from './states/filter-view-process-states.service';
import {UpdateStateAppListenerService} from './states/data/update-state-app-listener.service';
import {StateAppDataService} from './states/data/state-app-data.service';
import {NotificationsProcessDataService} from './preferences/data/notifications-process-data.service';
import {NotificationsProcessStatesService} from './preferences/data/notifications-process-states.service';
import {StateNotificationsService} from './states/data/state-notifications.service';
import {FiltersViewProcessDataService} from './games/game-filter/data/filters-view-process-data.service';
import {GamesMenuWidgetProcessDataService} from './games/menu/games-menu-widget-process-data.service';
import {GamesMenuWidgetProcessStatesService} from './games/menu/games-menu-widget-process-states.service';
import {StateAppService} from './states/state-app.service';
import {AlertsService, Logger, TranslationsService} from 'common';
import {TranslationsUsService} from './i18n/translations-us.service';
import {TicketGameUserFiltersDataService} from './games/tickets/data/ticket-game-user-filters-data.service';
import {TicketGameUserFiltersService} from './games/tickets/data/ticket-game-user-filters.service';
import {NoopTicketGameUserFiltersService} from './games/tickets/data/noop-ticket-game-user-filters.service';
import {StateModalService} from './states/state-modal.service';
import {BoothsDao, BoothsLocalDao, BoothsRemoteDao} from './booth/data/booths-dao';
import {BoothViewDataService} from './booth/booth-view-data.service';
import {BoothRemoteViewDataService} from './booth/booth-view-remote-data.service';
import {BoothLocalViewDataService} from './booth/booth-view-local-data.service';
import {GeolocationService} from './geolocation/geolocation.service';
import {GeolocationViewService} from './geolocation/geolocation-view.service';
import {StatesService} from './states/data/states.service';
import {SessionService} from './user/auth/session.service';
import {UserService} from './user/data/user.service';
import {StatesDao} from './states/data/states.dao';
import {ResultsListByStateService} from './games/results/data/results-list-by-state.service';
import {ResultsListService} from './games/results/data/results-list.service';
import {ResultsService} from './games/results/data/results.service';
import {ResultsDao} from './games/results/data/results.dao';

const PROVIDERS = [];

if (environment.geolocation.enableAppState) {
  PROVIDERS.push([
    {
      provide: UpdateStateAppListenerService,
      useClass: UpdateStateAppListenerService,
      deps: [
        StatesService,
        StateAppDataService,
        SessionService,
        UserService,
        StatesDao,
        Logger, // temporal remove after WEB-2567
      ],
    },
    {
      provide: UpcomingGamesProcessDataService,
      useClass: UpcomingGamesProcessStatesService,
      deps: [GameMetadataService, StateAppDataService],
      multi: true,
    },
    {
      provide: GamesMenuWidgetProcessDataService,
      useClass: GamesMenuWidgetProcessStatesService,
      deps: [GameMetadataService, StateAppDataService],
      multi: true,
    },
    {
      provide: BannerProcessDataService,
      useClass: BannerProcessStatesService,
      deps: [StateAppDataService],
      multi: true,
    },
    {
      provide: FiltersViewProcessDataService,
      useClass: FilterViewProcessStatesService,
      deps: [GameMetadataService, StateAppDataService, UpcomingGamesService],
      multi: true,
    },
    {
      provide: ResultFiltersViewProcessDataService,
      useClass: FilterViewProcessStatesService,
      deps: [GameMetadataService, StateAppDataService, UpcomingGamesService],
      multi: true,
    },
    StateNotificationsService,
    {
      provide: NotificationsProcessDataService,
      useClass: NotificationsProcessStatesService,
      deps: [StateNotificationsService],
      multi: true,
    },
    StateAppService,
    {
      provide: TranslationsService,
      useClass: TranslationsUsService,
    },
    StateModalService,
    ResultsListByStateService,
    {
      provide: ResultsListService,
      useClass: ResultsListByStateService,
      deps: [ResultsDao, ResultsService, StateAppDataService, GameMetadataService],
    },
  ]);
}

if (environment.features.booths.remoteBooths) {
  PROVIDERS.push([
    {
      provide: BoothsDao,
      useClass: BoothsRemoteDao,
    },
    {
      provide: BoothViewDataService,
      useClass: BoothRemoteViewDataService,
      deps: [
        BoothsDao,
        GeolocationService,
        GeolocationViewService,
        Logger,
        AlertsService,
        StateAppService,
      ],
    },
  ]);
} else {
  PROVIDERS.push([
    {
      provide: BoothsDao,
      useClass: BoothsLocalDao,
    },
    {
      provide: BoothViewDataService,
      useClass: BoothLocalViewDataService,
      deps: [
        BoothsDao,
        GeolocationService,
        GeolocationViewService,
        Logger,
        AlertsService,
      ],
    },
  ]);
}

if (environment.features.groups.filterGames) {
  PROVIDERS.push([
    {
      provide: UpcomingGamesGroupsProcessDataService,
      useClass: UpcomingGamesGroupsProcessPlayableService,
      deps: [GameMetadataService],
      multi: true,
    },
  ]);
}

if (environment.features.tickets.filterGames) {
  PROVIDERS.push([TicketGameUserFiltersDataService]);
} else {
  PROVIDERS.push([
    {
      provide: TicketGameUserFiltersService,
      useClass: NoopTicketGameUserFiltersService,
    },
  ]);
}

export function getDynamicProviders() {
  return PROVIDERS;
}
