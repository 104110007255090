import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import {TranslatableText} from '../translatable-text';
import {Router} from '@angular/router';
import {UriMapper} from '../../uri/uri-mapper';
import {ModalHelperService} from '../../modal/modal-helper.service';

@Component({
  selector: 'tl-translatable-text',
  templateUrl: './translatable-text.component.html',
  styleUrls: ['./translatable-text.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TranslatableTextComponent implements AfterViewInit {
  @Input()
  text: string | TranslatableText = '';

  @Output()
  handleInternalLink = new EventEmitter<string>();

  constructor(
    private router: Router,
    private ref: ElementRef,
    private uriMapper: UriMapper,
    private modalService: ModalHelperService,
  ) {}

  ngAfterViewInit(): void {
    let anchors = [...this.ref.nativeElement.querySelectorAll('a')];
    anchors = anchors
      .filter(a => a.hasAttribute('data-href'))
      .filter(a => this.uriMapper.isInternalRoute(a.getAttribute('data-href')));
    anchors.forEach(a =>
      a.addEventListener('click', e => {
        e.preventDefault();
        if (this.handleInternalLink.observed) {
          this.handleInternalLink.emit(a.getAttribute('data-href'));
        } else {
          this.manageNavigationDestination(a.href);
        }
      }),
    );
  }

  private manageNavigationDestination(urlString: string) {
    const url = new URL(urlString);
    this.modalService.closeLastModal();
    this.router.navigateByUrl(url.pathname);
  }
}
