import {Injectable} from '@angular/core';
import {AbstractObservableDataService, DistanceComparator} from 'common';
import {Observable, ReplaySubject} from 'rxjs';
import {first, map} from 'rxjs/operators';

import {GeolocationService} from '../../geolocation/geolocation.service';

import {GeolocatedLotteryBooth} from './geolocated-lottery-booth';
import {LotteryBooth} from './lottery-booth';

@Injectable({providedIn: 'root'})
export class LotteryBoothService extends AbstractObservableDataService<
  Array<LotteryBooth>
> {
  private stateBoothMap = new ReplaySubject<Map<string, Array<LotteryBooth>>>(1);

  constructor(private geolocationService: GeolocationService) {
    super();
  }

  getLotteryBooth(id: string): Observable<LotteryBooth> {
    return this._data.pipe(map(list => list.find(t => t.id === id)));
  }

  setData(data: Array<LotteryBooth>): void {
    super.setData(data);

    let stateMap = new Map<string, Array<LotteryBooth>>();
    data.forEach(booth => {
      const state = booth.state ? booth.state.toLowerCase() : '-';
      if (booth.state && stateMap.has(state)) {
        stateMap.get(state).push(booth);
      } else {
        stateMap.set(state, [booth]);
      }
    });

    this.stateBoothMap.next(stateMap);
  }

  getStateBoothMap(): Observable<Map<string, Array<LotteryBooth>>> {
    return this.stateBoothMap.asObservable();
  }

  geolocateBooths(
    position: GeolocationPosition,
  ): Observable<Array<GeolocatedLotteryBooth>> {
    return this.getData().pipe(
      map(booths =>
        booths
          .map(booth => {
            const distance = this.geolocationService.getDistance(
              {latitude: +booth.latitude, longitude: +booth.longitude},
              position.coords,
            );
            return new GeolocatedLotteryBooth(booth, distance);
          })
          .sort(new DistanceComparator('distance').compare),
      ),
    );
  }

  /**
   * Return the booth with the distance between user and booth
   *
   * @param position
   * @param boothId
   */
  geolocateBooth(
    position: GeolocationPosition,
    boothId: string,
  ): Observable<GeolocatedLotteryBooth> {
    return this.getData().pipe(
      first(),
      map(booths => {
        const booth = booths.find(b => b.id === boothId);
        const distance = this.geolocationService.getDistance(
          {latitude: +booth.latitude, longitude: +booth.longitude},
          position.coords,
        );
        return new GeolocatedLotteryBooth(booth, distance);
      }),
    );
  }

  getBoothNumber(): Observable<number> {
    return this.getData().pipe(
      first(),
      map(booths => booths.filter(booth => booth.enabled)),
      map(booths => booths.length),
    );
  }
}
