import {AfterContentInit, Attribute, Directive, Input} from '@angular/core';
import {NEVER, Observable, Subject} from 'rxjs';
import {filter, finalize, takeUntil} from 'rxjs/operators';

import {ScrollEvent} from '../scrolling/scroll-event';
import {ScrollableComponent} from '../scrolling/scrollable/scrollable.component';
import {isNumeric} from '../util/core/number';
import {Destroyable} from '../util/destroyable';

import {TriggerStyleService} from './trigger-style.service';
import {Router} from '@angular/router';

/**
 * Detects when scroll is going up or is going down.
 * Emits false if the direction is down and true if direction is up.
 */
@Directive({selector: 'tl-scrollable[tlTriggerStyleScroll]'})
export class TriggerStyleScrollDirective implements AfterContentInit {
  @Input('tlTriggerStyleScrollOffset') offSetTop = 0;

  @Input()
  destroy: Observable<any> | Subject<any> = NEVER;

  private lastScrollTop: number;

  private scrollHeight: number;

  private state = false;

  @Destroyable()
  private destroyRef = new Subject<void>();

  constructor(
    private scrollable: ScrollableComponent,
    @Attribute('tlTriggerStyleScroll') private id: string,
    private triggerStyleService: TriggerStyleService,
    private router: Router,
  ) {}

  ngAfterContentInit() {
    this.scrollable.scrollChangeOutsideAngular
      .pipe(
        takeUntil(this.destroyRef),
        takeUntil(this.router.events),
        takeUntil(this.destroy),
        // Fix safari scroll physics, in safari scroll could be less than 0
        // and more than 100% when is bouncing due to acceleration physics
        // scrollPercent must be between 0 and 100,
        // because safari allows negative scroll when is bouncing
        filter(
          event =>
            event.scrollTop > 0 &&
            event.scrollPercent <= 100 &&
            event.scrollPercent >= 0,
        ),
        finalize(() => {
          this.state = false;
          this.triggerStyleService.triggerEvent(this.id, false);
        }),
      )
      .subscribe(event => {
        if (this.hasSameScrollHeight(event) && this.outsideOffSet(event)) {
          if (this.isScrollingDown(event)) {
            this.state = true;
            this.triggerStyleService.triggerEvent(this.id, true);
          } else if (
            this.isScrollingUp(event) ||
            event.scrollPercent === 0 ||
            event.scrollPercent === 100
          ) {
            this.state = false;
            this.triggerStyleService.triggerEvent(this.id, false);
          }
        } else if (event.element.nativeElement.scrollHeight < this.scrollHeight) {
          this.state = false;
          this.triggerStyleService.triggerEvent(this.id, false);
        }

        this.lastScrollTop = event.scrollTop;
        this.scrollHeight = event.element.nativeElement.scrollHeight;
      });
  }

  reset() {
    this.state = false;
    this.triggerStyleService.triggerEvent(this.id, false);
  }

  private isScrollingDown(event: ScrollEvent) {
    return (
      !this.state &&
      isNumeric(this.lastScrollTop) &&
      event.scrollTop > this.lastScrollTop
    );
  }

  private isScrollingUp(event: ScrollEvent) {
    return (
      this.state &&
      isNumeric(this.lastScrollTop) &&
      event.scrollTop < this.lastScrollTop
    );
  }

  private hasSameScrollHeight(event: ScrollEvent) {
    return this.scrollHeight === event.element.nativeElement.scrollHeight;
  }

  private outsideOffSet(event: ScrollEvent) {
    return event.scrollTop > this.offSetTop;
  }
}
