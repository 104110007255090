import {
  isNumeric,
  Serializable,
  SerializableProperty,
  SerializableType,
  TranslatableText,
} from 'common';
import {isFuture, isPast, isToday} from 'date-fns';

import {environment} from '~environments/environment';

import {Club} from '../../clubs/data/club';
import {ClubType} from '../../clubs/data/club-type';
import {GamePrize} from '../../prizes/data/game-prize';
import {TicketRenderData} from '../../tickets/render/ticket-render-data';

import {UpcomingGameUiMetadata} from './upcoming-game-ui-metadata';

class UpcomingGameInternal {
  raffleDate: number;

  closeDate: number;

  openDate: number;

  messageWhenClose: string | TranslatableText;

  clubsClosingDate: number;

  rocketsClosingDate: number;

  jackpot: number;

  jackpotDescription: string;

  price: number;

  gameId: string;

  name: string;

  alias: string;

  raffleId: number;

  raffleNumber: number;

  highlightedImage: string;

  highlightedImageSmall: string;

  groupClosingDate: number;

  gameVersion: number;

  shipmentClosingDate: number;

  collectionClosingDate: number;

  companyClubCloseDeadline: number;

  @SerializableProperty(Club)
  clubs: Array<Club>;

  @SerializableProperty(TicketRenderData, SerializableType.OBJECT)
  renderData: TicketRenderData;

  @SerializableProperty(Map, SerializableType.MAP)
  jackpotsInfo: Map<string, number>;

  @SerializableProperty(GamePrize, SerializableType.COLLECTION)
  gamePrizes: Array<GamePrize>;

  showcaseImage: string;

  hasCompanyClubs: boolean;

  promos: Array<string>;

  @SerializableProperty(UpcomingGameUiMetadata, SerializableType.OBJECT)
  uiMetadata: UpcomingGameUiMetadata;

  isClosed() {
    return isPast(this.closeDate);
  }

  isClosedForCollection(): boolean {
    return isPast(this.collectionClosingDate);
  }

  isClosedForShipment(): boolean {
    return isPast(this.shipmentClosingDate);
  }

  isAfterOpenning() {
    return !!this.openDate ? isPast(this.openDate) : true;
  }

  isAvailable() {
    return !this.isClosed() && this.isAfterOpenning();
  }

  isRaffleCelebrated() {
    return isPast(this.raffleDate);
  }

  closesToday() {
    return isToday(this.closeDate);
  }

  hasClubsOpened(): boolean {
    return (
      (this.clubsClosingDate && isFuture(this.clubsClosingDate)) ||
      (this.rocketsClosingDate && isFuture(this.rocketsClosingDate))
    );
  }

  hasClubsOpenedWithCompanyClubs(): boolean {
    return (
      this.hasClubsOpened() ||
      (this.hasCompanyClubs &&
        this.companyClubCloseDeadline &&
        isFuture(this.companyClubCloseDeadline))
    );
  }

  hasClubsWithGroupId(groupId: number): boolean {
    return (
      this.clubs &&
      !!this.clubs.length &&
      this.clubs.some(club => club.groupId === groupId)
    );
  }

  hasClubs(): boolean {
    return (
      this.clubs &&
      this.clubs.length &&
      this.clubs.some(c => c.type === ClubType.CLUB)
    );
  }

  hasRockets(): boolean {
    return (
      this.clubs &&
      this.clubs.length &&
      this.clubs.some(c => c.type === ClubType.ROCKET)
    );
  }

  hasGamePrizes(): boolean {
    return this.gamePrizes && this.gamePrizes.length > 0;
  }

  isHighlighted(forDesktop: boolean): boolean {
    return (
      (forDesktop && !!this.highlightedImage) ||
      (!forDesktop && !!this.highlightedImageSmall)
    );
  }

  isBookingMode(): boolean {
    if (!environment.features.reserveMode) return false;
    return !!this.openDate ? isFuture(this.openDate) : false;
  }
}

export class UpcomingGame extends Serializable(UpcomingGameInternal) {
  public static createFromBackend(obj: any): any {
    let upgame = new UpcomingGame();

    upgame.raffleDate = obj.fechaSorteo;
    upgame.closeDate = obj.fechaCierreAdministracion;
    upgame.openDate = obj.fechaApertura;
    upgame.messageWhenClose = obj.messageWhenClosed || {
      key: 'games.upcomingGames.gameRow.messageClosedAdmin',
    };
    upgame.clubsClosingDate = obj.fechaCierrePenyas;
    upgame.rocketsClosingDate = obj.fechaCierreRockets;
    upgame.jackpot = obj.bote;
    upgame.jackpotDescription = obj.desc;
    upgame.price = obj.precio;
    upgame.gameId = obj.juego;
    upgame.name = obj.nombre;
    upgame.alias = obj.alias;
    upgame.raffleId = obj.sorteoId;
    upgame.raffleNumber = obj.numSorteo;
    upgame.highlightedImage = obj.imagenPromocionalWeb;
    upgame.highlightedImageSmall = obj.imagenPromocional;
    upgame.groupClosingDate = obj.fechaCierrePenyas;
    upgame.gameVersion = obj.juegoVersion;
    upgame.shipmentClosingDate = obj.fechaCierreEnvioCasa;
    upgame.collectionClosingDate = obj.fechaCierreRecogida;
    upgame.companyClubCloseDeadline = obj.fechaCierrePenyasUser;
    upgame.hasCompanyClubs = obj.specialLink === 'empresas';
    upgame.showcaseImage = obj.playImage;

    upgame.promos = obj.specialPromos;

    upgame.clubs = obj.penyas
      ? obj.penyas.map(p => Club.createFromBackend(p))
      : undefined;

    upgame.renderData = obj.extraInfo
      ? TicketRenderData.createFromBackend(obj.extraInfo)
      : undefined;

    if (obj.jackpotInfos && obj.jackpotInfos.length > 1) {
      upgame.jackpotsInfo = new Map<string, number>();
      obj.jackpotInfos.forEach((jackpot, index) => {
        if (index > 0 && isNumeric(jackpot.amount)) {
          upgame.jackpotsInfo.set(jackpot.label, jackpot.amount);
        }
      });

      upgame.jackpotsInfo =
        upgame.jackpotsInfo.size > 0 ? upgame.jackpotsInfo : null;
    }

    upgame.gamePrizes = obj.prizeInfos
      ? obj.prizeInfos.map(prizeInfo => GamePrize.createFromBackend(prizeInfo))
      : null;

    upgame.uiMetadata = obj.uiInfo
      ? UpcomingGameUiMetadata.createFromBackend(obj.uiInfo)
      : undefined;

    return upgame;
  }
}
