import {Serializable, SerializableDisableWarning} from 'common';

import {Booth} from './booth';
import {environment} from '~environments/environment';

class LotteryBoothInternal {
  id: string;

  name: string;

  email: string;

  picture: string;

  @SerializableDisableWarning()
  phones: Array<string>;

  address: string;

  city: string;

  state: string;

  zipCode: string;

  number: number;

  latitude: string;

  longitude: string;

  enabled: boolean;

  map: string;

  mapsLink: string;

  get hasPhones(): boolean {
    return this.phones && this.phones.length > 0;
  }

  get addressFirstLine(): string {
    return this.address;
  }

  get addressSecondLine(): string {
    return `${this.zipCode} ${this.city} ${this.state ? `(${this.state})` : ''}`;
  }
}

/** Maybe to be @deprecated Migrate to Booth
 *  After WEB-2905 completed
 */
export class LotteryBooth extends Serializable(LotteryBoothInternal) {
  static createFromBackend(obj: any): LotteryBooth {
    let booth = new LotteryBooth();

    booth.id = obj.id;
    booth.name = obj.commercial ? obj.commercial : obj.nombre;
    booth.email = obj.mail;
    booth.picture = obj.urlFoto;
    booth.phones = obj.telefono
      ? obj.telefono.split('/').map((phone: string) => phone.trim())
      : [];
    booth.address = obj.direccion;
    booth.city = obj.poblacion;
    booth.state = obj.provincia;
    booth.zipCode = obj.codigoPostal;
    booth.number = obj.numero;
    booth.latitude = obj.latitud;
    booth.longitude = obj.longitud;
    booth.enabled = obj.enabled;
    booth.map = obj.mapImage;

    if (booth.latitude) {
      booth.mapsLink =
        environment.google.maps.queryLink +
        encodeURIComponent(`${booth.latitude},${booth.longitude}`);
    } else {
      booth.mapsLink =
        environment.google.maps.queryLink +
        encodeURIComponent(
          `${booth.name},${booth.address},${booth.city},${booth.state}`,
        );
    }

    return booth;
  }

  static createFromBooth(obj: Booth): LotteryBooth {
    let booth = new LotteryBooth();

    booth.id = obj.id;
    booth.name = obj.comercialName ? obj.comercialName : obj.name;
    booth.email = obj.email;
    booth.picture = obj.pictureUrl;
    booth.address = obj.street;
    booth.city = obj.city;
    booth.state = obj.region;
    booth.zipCode = obj.zip;
    booth.latitude = '' + obj.latitude;
    booth.longitude = '' + obj.longitude;
    booth.enabled = obj.enabled;

    if (booth.latitude) {
      booth.mapsLink =
        environment.google.maps.queryLink +
        encodeURIComponent(`${booth.latitude},${booth.longitude}`);
    } else {
      booth.mapsLink =
        environment.google.maps.queryLink +
        encodeURIComponent(
          `${booth.name},${booth.address},${booth.city},${booth.state}`,
        );
    }

    return booth;
  }
}
