import {Injectable} from '@angular/core';
import {AbstractObservableDataService, PageableService} from 'common';
import {Observable, Observer, of} from 'rxjs';
import {first, map, switchMap} from 'rxjs/operators';

import {FilteredDataService} from '../../../common/list/filtered-data-service';
import {Filter} from '../../game-filter/data/filter';
import {SelectableRaffle} from '../../selectable-raffles/data/selectable-raffle';

import {GenericResult} from './generic-result';
import {ResultsDao} from './results.dao';

@Injectable({providedIn: 'root'})
export class ResultsService
  extends AbstractObservableDataService<Array<GenericResult>>
  implements
    FilteredDataService<GenericResult>,
    PageableService<Array<GenericResult>>
{
  paginationIndex = 0;

  paginationEnd = false;

  filter: Filter;

  constructor(private resultsDao: ResultsDao) {
    super();

    this._data.subscribe(r => (this.paginationIndex = r.length));
  }

  getResult(id: number): Observable<GenericResult> {
    return this._data.pipe(map(list => list.find(t => t.id === id)));
  }

  getResultsByGame(gameId: string): Observable<Array<GenericResult>> {
    return this._data.pipe(map(list => list.filter(r => r.gameId === gameId)));
  }

  getSelectableRafflesFromResults(
    gameId: string,
  ): Observable<Array<SelectableRaffle>> {
    return this.getResultsByGame(gameId).pipe(
      map(l =>
        l.map(
          result =>
            new SelectableRaffle(
              result.id,
              result.name,
              result.date,
              result.date,
              result.closingDate,
              result.price,
              result.gameVersion,
            ),
        ),
      ),
    );
  }

  getResultForRaffle(raffleId: number): Observable<GenericResult> {
    return this._data.pipe(map(list => list.find(r => r.id === raffleId)));
  }

  loadMore(): Observable<Array<GenericResult>> {
    return new Observable((observer: Observer<Array<GenericResult>>) => {
      this.resultsDao
        .moreResults(this.paginationIndex, this.filter ? this.filter.gameIds : null)
        .subscribe(results => {
          this._data.pipe(first()).subscribe({
            next: current => {
              const currentPaginationIndex = this.paginationIndex;
              this.paginationIndex += results.data.length;
              let newData: Array<GenericResult>;

              if (currentPaginationIndex > 0) {
                newData = current.concat(results.data);
              } else {
                newData = results.data;
              }
              this._data.next(newData);

              if (
                this.paginationIndex >= results.total ||
                results.data.length === 0
              ) {
                this.paginationEnd = true;
              }
              observer.next(newData);
              observer.complete();
            },
            error: e => observer.error(e),
          });
        });
    });
  }

  reset(): Observable<Array<GenericResult>> {
    this.paginationEnd = false;
    this.paginationIndex = 0;

    return this.loadMore();
  }

  /**
   * Get a result with a fallback to the API if it's not in the cache.
   *
   * @param id The result ID.
   */
  getResultWithFallback(id: number) {
    return this.getResult(id).pipe(
      first(),
      switchMap((result: GenericResult) => {
        if (result) {
          return of(result);
        } else {
          return this.resultsDao.getResult(id);
        }
      }),
    );
  }
}
