import {Injectable} from '@angular/core';
import {EMPTY, Observable, ReplaySubject, Subject, throwError} from 'rxjs';
import {expand, first, startWith, switchMap, takeUntil} from 'rxjs/operators';

import {SessionService} from '../../../../user/auth/session.service';
import {CombinationType} from '../../../combination/data/combination-type';
import {CombinationTypeValue} from '../../../combination/data/combination-type-declaration';
import {CombinationValue} from '../../../combination/data/combination-value';
import {LotteryTicket} from '../../../lottery/data/lottery-ticket';
import {LotteryTicketPaginationData} from '../../../lottery/data/lottery-ticket-pagination-data';
import {LotteryDao} from '../../../lottery/data/lottery.dao';
import {DynamicLotteryBetFormService} from '../di/dynamic-lottery-bet-form.service';

import {AbstractLotterySearch} from './abstract-lottery-search';
import {LotenalBetFormService} from './lotenal-bet-form-service';
import {LotterySearchParams} from './lottery-search-params';
import {LotterySearchType} from './lottery-search-type';

@Injectable()
export class LotenalLotterySearchService extends AbstractLotterySearch {
  private static readonly MAX_SEARCH_LIMIT = 15;

  totalTickets = 0;

  searchTypes: Array<CombinationTypeValue>;

  isFiltered = new ReplaySubject<boolean>(1);

  private lotenalBetFormService: LotenalBetFormService;

  private stopSearch = new Subject<void>();

  get raffleId(): number {
    return this.raffle;
  }

  set raffleId(id: number) {
    this.raffle = id;
    this.lotenalBetFormService.updateRaffleId(id);
  }

  constructor(
    private dynamicLotteryBetFormService: DynamicLotteryBetFormService,
    private lotteryDao: LotteryDao,
    private sessionService: SessionService,
  ) {
    super();
    this.lotenalBetFormService = <LotenalBetFormService>(
      this.dynamicLotteryBetFormService.get(LotenalBetFormService)
    );
  }

  loadData(): Observable<LotteryTicketPaginationData> {
    return this.sessionService.isLoggedIn().pipe(
      first(),
      switchMap(isLoggedIn => {
        const operation = isLoggedIn
          ? this.lotteryDao.searchLotteryTicketsAsUser
          : this.lotteryDao.searchLotteryTickets;

        let foundTickets = 0;
        let currentSearches = 0;

        const searches = (<Observable<LotteryTicketPaginationData>>(
          operation.call(
            this.lotteryDao,
            this.raffleId,
            foundTickets,
            this.gameMetadata,
            this.searchTypes,
            this.minNumber,
          )
        )).pipe(
          expand(paginationData => {
            // loops until there are enough tickets, search limit is
            // reached or no tickets are found
            foundTickets += paginationData.tickets.length;
            currentSearches++;

            if (paginationData.tickets.length) {
              this.numberTickets = paginationData.searchableTickets;
              this.totalTickets = paginationData.ticketStock;
              paginationData.tickets.forEach(ticket =>
                this.lotenalBetFormService.registerSearchId(ticket),
              );
            }

            if (
              currentSearches >= LotenalLotterySearchService.MAX_SEARCH_LIMIT ||
              paginationData.searchableTickets <= foundTickets ||
              !paginationData.tickets.length
            ) {
              return EMPTY;
            } else {
              return <Observable<LotteryTicketPaginationData>>(
                operation.call(
                  this.lotteryDao,
                  this.raffleId,
                  foundTickets,
                  this.gameMetadata,
                  this.searchTypes,
                  this.minNumber,
                )
              );
            }
          }),
          takeUntil(this.stopSearch.asObservable()),
        ); // used to cancel previous
        // pending searches;

        const selectedTickets = this.lotenalBetFormService.getSelectedTickets();

        if (selectedTickets.length) {
          const selectedData = LotteryTicketPaginationData.createFromBackend({});
          selectedData.tickets =
            this.filterSelectedTicketsWithSearch(selectedTickets);

          if (selectedData.tickets?.length) {
            return searches.pipe(startWith(selectedData));
          }
        }
        return searches;
      }),
    );
  }

  setSearchParams(params: LotterySearchParams): void {
    this.searchTypes = params ? params.get(LotterySearchType.VALUE_TYPES) : null;
    this.minNumber = params?.get(LotterySearchType.MIN_NUMBER) ?? null;
    this.location = params?.get(LotterySearchType.LOCATION) ?? null;
    this.stopSearch.next();

    this.updateIsFiltered();
  }

  updateIsFiltered(): void {
    let filtered =
      this.searchTypes &&
      this.searchTypes.some((combinationType: CombinationTypeValue) =>
        combinationType.value.some(
          combinationValue =>
            (combinationValue instanceof CombinationValue &&
              combinationValue.value !== '') ||
            (combinationValue instanceof Array &&
              combinationValue.some(
                (subCombinationValue: CombinationValue) =>
                  subCombinationValue.value !== '',
              )),
        ),
      );
    filtered = filtered || this.minNumber > 1 || Boolean(this.location);
    this.isFiltered.next(filtered);
  }

  clear(): void {
    this.stopSearch.next();
    this.lotenalBetFormService.free();
    super.clear();
  }

  reset(): Observable<Array<LotteryTicket>> {
    this.stopSearch.next();
    this.lotenalBetFormService.free();
    return super.reset();
  }

  getStock(boothId: string): Observable<{stock: number; boothId: string}> {
    return throwError(() => new Error('Not supported in lotenal'));
  }

  getStockExcludeBooth(
    adminExclude: string,
    amount: number,
  ): Observable<{stock: number; boothId: string}> {
    return throwError(() => new Error('Not supported in lotenal'));
  }

  private filterSelectedTicketsWithSearch(
    tickets: Array<LotteryTicket>,
  ): Array<LotteryTicket> {
    if (!this.searchTypes) {
      return tickets;
    }

    return tickets.filter(
      ticket =>
        !this.searchTypes.some(type => {
          const ticketValue = ticket.value.find(t => t.typeId === type.typeId);
          const searchTypeValue = this.normalizeValue(type);
          let isLessThanMinNumber = false;
          if (this.minNumber) {
            isLessThanMinNumber = ticket.availableAmount < this.minNumber;
          }

          if (!isLessThanMinNumber && searchTypeValue === '') {
            return false;
          } else {
            const regex = new RegExp(searchTypeValue.replace(/_/g, '.') + '$');
            const ticketTypeValue = this.normalizeValue(ticketValue);

            return !regex.test(ticketTypeValue);
          }
        }),
    );
  }

  // TODO · move this somewhere useful / refactor CombinationValue names and
  // structure noinspection JSMethodCanBeStatic
  private normalizeValue(
    combValue: CombinationType<CombinationValue | Array<CombinationValue>>,
  ) {
    return Array.isArray(combValue.value[0])
      ? combValue.value[0][0].value
      : (combValue.value[0] as any).value;
  }
}
