<ng-container *ngIf="!!bigPrize">
  <div class="tl-big-prize">
    <div class="tl-big-prize__container">
      <div class="tl-big-prize__container__info">
        <img
          src="/assets/img/big-prize/confetti.svg"
          alt="confetti"
          class="tl-big-prize__container__info__confetti" />
        <img
          [src]="trophyImage"
          alt="trophy"
          class="tl-big-prize__container__info__trophy" />
        <span class="tl-big-prize__container__info__description">
          {{ 'bigPrize.info' | i18n }}
        </span>
        <span class="tl-big-prize__container__info__amount">
          {{
            (bigPrize.prize | money: null:null:'1.0-2') +
              (bigPrize.parimutuel ? '*' : '')
          }}
        </span>
        <span
          *ngIf="bigPrize.subtitle"
          class="tl-big-prize__container__info__subtitle">
          {{ bigPrize.subtitle }}
        </span>
      </div>
      <div class="tl-big-prize__container__footer">
        <span class="tl-big-prize__container__footer__legend">
          {{ bigPrize.legendLabel.key | i18n }}
        </span>
        <div class="tl-big-prize__container__footer__btns-wrapper">
          <button
            *ngFor="let btn of bigPrize.buttons"
            class="btn btn-type-4"
            [ngClass]="btn.class"
            (click)="onClick(btn)">
            <tl-translatable-text [text]="btn.label"></tl-translatable-text>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
