import {DecimalPipe} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {defer, interval, Observable, Subscription} from 'rxjs';
import {repeat, take, tap} from 'rxjs/operators';

import {ngModelProvider} from '../../model/ng-model-config';
import {ResponsiveService} from '../../responsive/responsive.service';
import {isNumeric} from '../../util/core/number';
import {AbstractNgModel} from '../abstract-ngmodel';
import {MoneyPipe} from '../../money/money.pipe';
import {CounterStep} from './counter-step';
import {parseCurrency} from '../../util/money';

@Component({
  selector: 'tl-counter',
  templateUrl: './counter.component.html',
  providers: [ngModelProvider(CounterComponent)],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CounterComponent
  extends AbstractNgModel<number>
  implements OnInit, OnChanges
{
  model = 0;

  displayModel = '';

  backupDisplayModel = '';

  // Used to know what was the last displayModel formatted in blur
  lastBlurDisplayModel = '';

  @Output()
  changeAmount = new EventEmitter<number>();

  /**
   * Emits when plus or minus buttons are clicked while at the max or min
   * values.
   */
  @Output()
  reachLimit = new EventEmitter<'prev' | 'next'>();

  @Input('minimum')
  min: number;

  @Input()
  minAmountHandle: number;

  @Input('maximum')
  max: number;

  @Input()
  step = 1;

  @Input()
  customSteps: Array<CounterStep>;

  /**
   * Set amount min to use step
   */
  @Input()
  minAmountStep = 0;

  /**
   * Allow only steps which are divisors of max value
   */
  @Input()
  divisorSteps = false;

  @Input()
  @HostBinding('class.tl-counter--readonly')
  readOnly = false;

  /**
   * Allow only integer numbers when changing
   */
  @Input()
  integer = false;

  /**
   * Allows input writable
   */
  @Input()
  manual = false;

  @Input('delay')
  initialDelay = 300;

  prevDisabled: boolean;

  nextDisabled: boolean;

  @Input()
  showDecimals = false;

  digitsInfo = '1.0-2';

  @Input()
  showAsCurrency = false;

  // Convert displayed value to number when use formatDisplayValueFn
  @Input() parseDisplayValueFn: (
    value: string,
    oldValue: string,
    min: number,
    max: number,
  ) => number;

  @Input() formatDisplayValueFn: (
    number: number,
    lastDisplayModel: string,
  ) => string;

  localeCode: string;

  @HostBinding('class.tl-counter')
  readonly hostClass = true;

  disabled = false;
  private delay: number;
  private modelPreviousKeyPress: number;
  private preventClick = false;
  private buttonPressed: HTMLButtonElement;
  private acceleratorSubs: Subscription;
  private acceleratorObs: Observable<void>;

  @Input()
  set value(v: number) {
    this.writeValue(v);
  }

  get isMobile() {
    return this.responsiveService.isMobile();
  }

  constructor(
    protected cdr: ChangeDetectorRef,
    protected responsiveService: ResponsiveService,
    @Inject(LOCALE_ID) protected locale: string,
  ) {
    super();

    this.localeCode = this.locale;
  }

  ngOnInit(): void {
    this.acceleratorObs = <Observable<void>>defer(() => interval(this.delay)).pipe(
      take(1),
      repeat(),
      tap(() => {
        if (this.delay > 40) {
          this.delay -= this.delay * 0.2;
        }
      }),
    );

    if (this.localeCode === 'pt-PT') {
      // WEB-1913 -- Format currency numbers same spain format
      this.localeCode = 'es-ES';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('max')) {
      if (this.showDecimals && changes.max.currentValue) {
        this.max = Number(changes.max.currentValue.toFixed(2));
      }
      if (this.max && this.model > this.max && !this.readOnly) {
        this.updateModel(this.max);
      } else if (isNumeric(this.model)) {
        this.disablePrevNext();
      }
    }
    if (changes.hasOwnProperty('customSteps') && this.customSteps) {
      this.customSteps = this.customSteps.sort((a, b) => a.from - b.from);
      this.min = this.customSteps[0].from;
    }

    if (changes.hasOwnProperty('min')) {
      if (this.showDecimals && changes.min.currentValue) {
        this.min = Number(changes.min.currentValue.toFixed(2));
      }
      if (this.min && this.model < this.min && !this.readOnly) {
        this.updateModel(this.min);
      } else if (isNumeric(this.model)) {
        this.disablePrevNext();
      }
    }

    if (changes.hasOwnProperty('showDecimals')) {
      if (this.showDecimals) {
        this.digitsInfo = '1.2-2';
      } else {
        this.digitsInfo = '1.0-2';
      }
    }
  }

  writeValue(obj: any): void {
    if (isNumeric(obj)) {
      this.updateModel(obj, false);
    }
  }

  keydown(event: KeyboardEvent): boolean {
    return !!event.key?.match(/[0-9,.]|Backspace|Delete/);
  }

  blur(event: Event): void {
    if (!this.manual) {
      return;
    }
    const input = <any>event.target;

    const parsedValued: number = !!this.parseDisplayValueFn
      ? this.parseDisplayValueFn(
          input.value,
          this.lastBlurDisplayModel,
          this.min,
          this.max,
        )
      : input.value;
    let valueFixed: number;
    if (!parsedValued) {
      return;
    }
    valueFixed = parseCurrency(
      parsedValued.toString(),
      this.localeCode,
      !this.integer,
      this.backupDisplayModel,
      this.min,
    );
    valueFixed = this.filterLimits(valueFixed);

    this.updateModel(valueFixed);
    this.lastBlurDisplayModel = this.displayModel;
  }

  onPressPrev(event: Event): void {
    this.onPress(event, this.previous.bind(this));
  }

  onPressNext(event: Event): void {
    this.onPress(event, this.next.bind(this));
  }

  onPressUp(event: any): void {
    if (!this.acceleratorSubs) {
      return;
    }
    if (this.responsiveService.isDesktop() && event.target === this.buttonPressed) {
      this.preventClick = true;
    }
    this.acceleratorSubs.unsubscribe();
    this.acceleratorSubs = undefined;
    if (this.modelPreviousKeyPress !== this.model) {
      this.updateModel(this.model, true);
    }
  }

  previous(notify?: boolean): void {
    this.updateAmount('prev', this.prevDisabled, notify);
    this.lastBlurDisplayModel = this.displayModel;
  }

  next(notify?: boolean): void {
    this.updateAmount('next', this.nextDisabled, notify);
    this.lastBlurDisplayModel = this.displayModel;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private onPress(event: Event, pressFn: (...args: any[]) => any): void {
    if (this.acceleratorSubs) {
      this.acceleratorSubs.unsubscribe();
    }
    this.buttonPressed = event.target as HTMLButtonElement;
    this.delay = this.initialDelay;
    this.modelPreviousKeyPress = this.model;
    pressFn();
    this.acceleratorSubs = this.acceleratorObs.subscribe(() => {
      pressFn();
    });
  }

  private updateAmount(
    type: 'prev' | 'next',
    isDisabled: boolean,
    notify: boolean,
  ): void {
    if (isDisabled && notify) {
      this.reachLimit.emit(type);
    }

    if (isDisabled || this.preventClick) {
      this.preventClick = false;
      return;
    }
    let step: number;
    if (!!this.customSteps && this.customSteps.length) {
      // Custom steps
      step = this.getCounterStep(type)?.step;
      if (step && this.model % step > 0) {
        const numStepsUpper = Math.ceil(this.model / step);
        if (type === 'next') {
          step = numStepsUpper * step - this.model;
        } else {
          step = this.model - (numStepsUpper - 1) * step;
        }
        if (step === 0) {
          step = this.getCounterStep(type).step;
        }
      }
    } else {
      const effectiveStep = this.calculateEffectiveStep(type);
      // When a number is a decimal, it is rounded to the nearest integer.
      if (this.model % effectiveStep > 0) {
        const numStepsUpper = Math.ceil(this.model / effectiveStep);
        if (type === 'next') {
          step = numStepsUpper * effectiveStep - this.model;
        } else {
          step = this.model - (numStepsUpper - 1) * effectiveStep;
        }
      } else {
        step = effectiveStep;
      }
    }

    if (isNumeric(this.max) && this.divisorSteps) {
      step = this.getDivisorStep(type);
    }

    let newValue: number;
    if (type === 'prev') {
      newValue = this.model - step;
      if (this.min && newValue < this.min) {
        newValue = this.min;
      }
    } else {
      newValue = this.model + step;
      if (this.max && newValue > this.max) {
        newValue = this.max;
      }
    }
    if (this.integer) {
      newValue = newValue < this.model ? Math.ceil(newValue) : Math.floor(newValue);
    }

    if (this.validate(newValue)) {
      this.updateModel(newValue, notify);
    }
  }

  private calculateEffectiveStep(type: 'next' | 'prev') {
    if (type === 'next') {
      if (this.model >= this.minAmountStep) {
        return !!this.customSteps ? this.getCounterStep(type)?.step ?? 0 : this.step;
      } else {
        return 1;
      }
    } else {
      if (this.model > this.minAmountStep) {
        return !!this.customSteps ? this.getCounterStep(type)?.step ?? 0 : this.step;
      } else {
        return 1;
      }
    }
  }

  private validate(model: number): boolean {
    return (
      (!isNumeric(this.min) && !isNumeric(this.max)) ||
      (isNumeric(this.min) && !isNumeric(this.max) && model >= this.min) ||
      (isNumeric(this.max) && !isNumeric(this.min) && model <= this.max) ||
      (isNumeric(this.min) &&
        isNumeric(this.max) &&
        model <= this.max &&
        model >= this.min &&
        (!this.integer || model === Math.floor(model)))
    );
  }

  private disablePrevNext(): void {
    this.prevDisabled =
      isNumeric(this.min) &&
      ((this.minAmountHandle && this.model <= this.minAmountHandle) ||
        this.model <= this.min ||
        (this.divisorSteps && this.getDivisorStep('prev') === -1));

    const effectiveStepNext = this.calculateEffectiveStep('next');
    this.nextDisabled =
      isNumeric(this.max) &&
      ((this.max - this.model < effectiveStepNext && this.model >= this.max) ||
        (this.divisorSteps && this.getDivisorStep('next') === -1));

    this.cdr.markForCheck();
  }

  private getDivisorStep(mode: 'prev' | 'next', step: number = this.step): number {
    if (!isNumeric(this.model)) {
      return;
    }

    let nextValue = mode === 'next' ? this.model + step : this.model - step;
    if (mode === 'next' ? nextValue > this.max / 2 : nextValue < 1) {
      return -1;
    }

    if (this.max % nextValue === 0) {
      return step;
    }

    return this.getDivisorStep(mode, step + this.step);
  }

  private filterLimits(value: number): number {
    return !!this.min && value < this.min
      ? this.min
      : !!this.max && this.max < value
      ? this.max
      : value;
  }

  private updateModel(value, notify = true): void {
    this.model = Number(value);
    this.disablePrevNext();

    this.updateDisplayModel(this.model);

    if (notify) {
      if (this.modelChange) {
        this.modelChange(this.model);
      }
      this.changeAmount.emit(this.model);
    }
  }

  private updateDisplayModel(value): void {
    if (this.formatDisplayValueFn) {
      if (!!this.model) {
        this.displayModel = this.formatDisplayValueFn(
          this.model,
          this.backupDisplayModel,
        );
      } else {
        this.displayModel = '';
      }
    } else if (this.showAsCurrency) {
      const moneyPipe = new MoneyPipe(this.localeCode);
      this.displayModel = moneyPipe.transform(
        value,
        null,
        null,
        this.digitsInfo,
        this.localeCode,
      );
    } else {
      const decimalPipe = new DecimalPipe(this.localeCode);
      this.displayModel = decimalPipe.transform(value, this.digitsInfo);
    }
    this.backupDisplayModel = this.displayModel;
    this.cdr.markForCheck();
  }

  private getCounterStep(type: 'prev' | 'next'): CounterStep {
    return this.customSteps.find((counterStep: CounterStep) =>
      type === 'next'
        ? this.model >= counterStep.from &&
          (!counterStep.to || this.model < counterStep.to)
        : (!counterStep.to || this.model <= counterStep.to) &&
          this.model > counterStep.from,
    );
  }
}
