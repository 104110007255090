import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SignatureModalComponent} from './signature-modal.component';
import {
  I18nModule,
  LoadingModule,
  ModalDialogModule,
  TranslatableTextModule,
} from 'common';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [SignatureModalComponent],
  imports: [
    CommonModule,
    ModalDialogModule,
    TranslatableTextModule,
    ReactiveFormsModule,
    I18nModule,
    LoadingModule,
  ],
})
export class SignatureModalModule {}
