import {Pipe, PipeTransform} from '@angular/core';
import {TranslatableText} from './translatable-text';

@Pipe({
  name: 'isTranslatableText',
})
export class IsTranslatableTextPipe implements PipeTransform {
  transform(value: TranslatableText | string): value is TranslatableText {
    return (
      value !== null &&
      typeof value === 'object' &&
      'key' in value &&
      typeof value.key === 'string' &&
      (!('keyData' in value) ||
        value.keyData === undefined ||
        typeof value.keyData === 'object')
    );
  }
}
