import {AbstractObservableDataService, Logger, PageableService} from 'common';
import {Observable, zip} from 'rxjs';
import {first, map} from 'rxjs/operators';

export abstract class MoneyActivityService<T>
  extends AbstractObservableDataService<Array<T>>
  implements PageableService<Array<T>>
{
  paginationEnd = false;

  paginationIndex = 0;

  maxPagination: number;

  protected filterType: Array<string> = null;

  protected constructor(protected logger: Logger) {
    super();
  }

  protected abstract getActivitiesDao(): Observable<Array<T>>;

  /**
   * Check if exists any activity type we don't know.
   * If exists, logged it as a warning.
   */
  protected abstract checkUnhandledActivityTypes(
    activities: Array<T>,
  ): Observable<any>;

  loadMore(): Observable<Array<T>> {
    return zip(
      this._data.asObservable().pipe(first()),
      this.getActivitiesDao(),
    ).pipe(
      map(([currentActivities, freshActivities]) => {
        let returnActivities = currentActivities;

        if (freshActivities.length > 0) {
          let newActivities: Array<T>;
          // Limit activities length to amount which backend says.
          if (
            this.maxPagination &&
            currentActivities.length + freshActivities.length > this.maxPagination
          ) {
            const remainingActivities =
              this.maxPagination - currentActivities.length;
            newActivities = currentActivities.concat(
              freshActivities.slice(0, remainingActivities),
            );
            this.setPaginationEnd(true);
          } else {
            newActivities = currentActivities.concat(freshActivities);
          }

          this.setPaginationIndex(newActivities);
          super.setData(newActivities);
          returnActivities = newActivities;
        } else {
          this.setPaginationEnd(true);
        }

        return returnActivities;
      }),
    );
  }

  reset(): Observable<Array<T>> {
    this.setPaginationEnd(false);
    this.filterType = null;
    this.setData([]);
    return this.loadMore();
  }

  setData(activities: Array<T>): void {
    this.setPaginationIndex(activities);
    this.checkUnhandledActivityTypes(activities).subscribe();
    super.setData(activities);
  }

  setMaxPagination(max: number): void {
    this.maxPagination = max;
  }

  setPaginationIndex(activities: Array<T>): void {
    this.paginationIndex = activities.length;
  }

  setFilterType(filterType: Array<string>): void {
    this.filterType = filterType;
    this.setPaginationEnd(false);
    this.setData([]);
  }

  setPaginationEnd(paginationEnd: boolean): void {
    this.paginationEnd = paginationEnd;
  }
}
