import {EventEmitter, Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {
  AlertsService,
  Country,
  CountryService,
  ModalDialogComponent,
  ModalHelperService,
  ResponsiveService,
  StorableContext,
  StorableContextEnum,
  StorableContextService,
} from 'common';
import {EMPTY, Observable, of, ReplaySubject, zip} from 'rxjs';
import {catchError, filter, first, map, switchMap, tap} from 'rxjs/operators';
import {environment} from '~environments/environment';

import {Endpoint} from '../../backend/endpoint/endpoint';
import {EndpointService} from '../../backend/endpoint/endpoint.service';
import {PrivateInfo} from '../../backend/private-info/private-info';
import {PrivateInfoService} from '../../backend/private-info/private-info.service';
import {LotteryBoothService} from '../../booth/data/lottery-booth.service';
import {TaskContext} from '../../common/scheduler/task-context';
import {TaskManager} from '../../common/scheduler/task-manager';
import {ErrorService} from '../../error/error.service';
import {TuLoteroServerError} from '../../error/tulotero-server-error';
import {LimitReachedModalComponent} from '../../exclusion/limit-reached-modal/limit-reached-modal.component';
import {Filter} from '../../games/game-filter/data/filter';
import {GenericTicket} from '../../games/tickets/data/generic-ticket';
import {TicketsService} from '../../games/tickets/data/tickets.service';
import {SessionService} from '../../user/auth/session.service';
import {UserService} from '../../user/data/user.service';

import {ShipmentBooth} from './shipment-booth';
import {ShipmentTicketComparator} from './shipment-ticket-comparator';
import {ShippingFormGroup} from './shipping-form-group';
import {TicketShippingDao} from './ticket-shipping.dao';
import {ShipmentAddressDao} from './shipment-address.dao';
import {ShipmentAddress} from './shipment-address';
import {State} from '../../states/data/state';
import {StatesService} from '../../states/data/states.service';
import {MoneyUtilsService} from '../../money/data/money-utils.service';
import {LoadBalanceGroupStorableContext} from '../../money/data/load-balance-shipment-storable-context';
import {ShippingAddressFormGroup} from './shipping-address-form-group';

@Injectable({providedIn: 'root'})
export class TicketShippingService {
  form: ShippingFormGroup | null = null;

  selectedTickets: Map<number, GenericTicket>;

  ticketSelectionEvent: EventEmitter<[number, boolean]> = new EventEmitter<
    [number, boolean]
  >();

  formReady = new ReplaySubject<ShippingFormGroup>(1);

  errorShippmentDisabled: string;

  private collectionOnly = false;

  private selectedBooths: Array<ShipmentBooth>;

  private selectedBoothsSubject = new ReplaySubject<Array<ShipmentBooth>>(1);

  private ticketsByBoothId: Map<string, Map<number, GenericTicket>>;

  private ticketsByBoothIdSubject = new ReplaySubject<
    Map<string, Map<number, GenericTicket>>
  >(1);

  private allLoadedTickets: Array<GenericTicket>;

  get isCollectionOnly(): boolean {
    return this.collectionOnly;
  }

  get isDesktop(): boolean {
    return this.responsiveService.isDesktop();
  }

  constructor(
    private alertService: AlertsService,
    private countryService: CountryService,
    private errorService: ErrorService,
    private lotteryBoothService: LotteryBoothService,
    private modalHelperService: ModalHelperService,
    private privateInfoService: PrivateInfoService,
    private responsiveService: ResponsiveService,
    private sessionService: SessionService,
    private router: Router,
    private taskManager: TaskManager,
    private ticketShippingDao: TicketShippingDao,
    @Inject('ActiveTicketsService') private ticketService: TicketsService,
    private shipmentAddressDao: ShipmentAddressDao,
    private statesService: StatesService,
    private userService: UserService,
    private endpointService: EndpointService,
    private moneyUtilsService: MoneyUtilsService,
    private storableContextService: StorableContextService,
  ) {
    this.init();
    this.sessionService.userLogoutEvent.subscribe(() => this.clearService());
  }

  init(): void {
    this.ticketService.clear();
    this.selectedTickets = new Map<number, GenericTicket>();

    this.ticketsByBoothId = new Map<string, Map<number, GenericTicket>>();
    this.ticketsByBoothIdSubject.next(this.ticketsByBoothId);

    this.selectedBooths = [];
    this.selectedBoothsSubject.next(this.selectedBooths);

    zip([this.statesService.getData(), this.countryService.getData()])
      .pipe(first())
      .subscribe(([states, countries]: [Array<State>, Array<Country>]) => {
        this.form = new ShippingFormGroup(
          this.ticketShippingDao,
          states.map((state: State) => state.name),
          countries,
          this.form?.address,
        ); // this.form?.address => For reuse address between services
        this.formReady.next(this.form);
      });

    this.collectionOnly = false;
    this.errorShippmentDisabled = undefined;
  }

  /**
   * This mode locks the shipment mode to collection and prevents selecting
   * shipping.
   */
  setCollectionOnlyMode(enabled: boolean): void {
    this.clearService();
    this.collectionOnly = enabled;
    this.form.get('ship').setValue(!enabled);
  }

  /**
   * Select a ticket for shipment. (add it to the form)
   */
  selectTicket(ticket: GenericTicket): void {
    if (!this.selectedTickets.has(ticket.id)) {
      this.selectedTickets.set(ticket.id, ticket);
      this.ticketSelectionEvent.emit([ticket.id, true]);

      this.form.addTicket(ticket);
    }
  }

  /**
   * Deselect a ticket for shipment. (remove it from the form)
   */
  deSelectTicket(ticket: GenericTicket, keepEmptyBooth?: boolean): void {
    if (this.selectedTickets.has(ticket.id)) {
      this.selectedTickets.delete(ticket.id);
      this.ticketSelectionEvent.emit([ticket.id, false]);

      this.form.removeTicket(ticket, keepEmptyBooth);
    }
  }

  isTicketSelected(ticket: GenericTicket): boolean {
    return this.selectedTickets.has(ticket.id);
  }

  /**
   * Adds a ticket to the list of loaded tickets.
   * For displaying purposes, doesn't add the ticket to the form.
   */
  addTicket(ticket: GenericTicket, notify = true): void {
    let boothId = ticket.bet.boothId;

    if (this.ticketsByBoothId.has(ticket.bet.boothId)) {
      this.ticketsByBoothId.get(boothId).set(ticket.id, ticket);
    } else {
      let ticketMap = new Map<number, GenericTicket>();
      ticketMap.set(ticket.id, ticket);
      this.ticketsByBoothId.set(boothId, ticketMap);
      this.lotteryBoothService
        .getLotteryBooth(boothId)
        .pipe(
          first(),
          map(booth => new ShipmentBooth(booth)),
        )
        .subscribe(booth => this.selectedBooths.push(booth));
    }

    if (notify) {
      this.notifyUpdates();
    }
  }

  /**
   * When not in collection locked mode, rebuilds the list of tickets to
   * display (and booths) from the list of selected tickets.
   */
  populateTicketsFromSelected(): void {
    if (!this.isCollectionOnly) {
      this.ticketsByBoothId = new Map<string, Map<number, GenericTicket>>();
      this.selectedBooths = [];

      Array.from(this.selectedTickets.values()).forEach(ticket =>
        this.addTicket(ticket, false),
      );
    }

    this.notifyUpdates();
  }

  /**
   * Loads all available tickets for the given booth, may include already
   * shipped tickets or not.
   *
   * Tries to load the tickets from the PrivateInfo ticket list
   * -if at least one ticket there is not active- or loads all active tickets
   * for the game by paginating the tickets filtered by game until pagination
   * ends or a non-active ticket is found.
   */
  loadAllActiveTicketsFor(
    boothId: string,
    includeShipped = false,
  ): Observable<boolean> {
    if (this.allLoadedTickets) {
      return of(this.addAllTicketsForAdmin(boothId));
    } else {
      return this.privateInfoService.getData().pipe(
        first(),
        switchMap((privateInfo: PrivateInfo) => {
          if (privateInfo.tickets && privateInfo.tickets.length) {
            let lastTicket = privateInfo.tickets[privateInfo.tickets.length - 1];

            if (!lastTicket.isActive()) {
              // every active ticket is loaded from allinfo
              this.allLoadedTickets = privateInfo.tickets;
            }
          }

          if (this.allLoadedTickets) {
            return of(this.addAllTicketsForAdmin(boothId, includeShipped));
          } else {
            // missing active tickets, paginate until we get all active
            // get gameId from current tickets
            let gameId: string;
            if (this.selectedTickets.size > 0) {
              gameId = this.selectedTickets.values().next().value.bet.gameId;
            } else {
              gameId = this.ticketsByBoothId.get(boothId).values().next().value
                .bet.gameId;
            }

            this.ticketService.filter = Filter.fromJSON({
              id: 'filter',
              gameIds: [gameId],
            });

            return this.ticketService.loadAllActiveTickets().pipe(
              map(list => (this.allLoadedTickets = list)),
              map(() => this.addAllTicketsForAdmin(boothId, includeShipped)),
            );
          }
        }),
      );
    }
  }

  clearAllTickets(): void {
    this.allLoadedTickets = null;
    this.ticketService.clear();
  }

  clearService(): void {
    this.clearAllTickets();
    this.form = null;
    this.init();
  }

  /**
   * Returns a map of all loaded tickets within each admin with the tickets as
   * an ordered array of tickets
   */
  getAllTickets(): Observable<Map<string, Array<GenericTicket>>> {
    return this.ticketsByBoothIdSubject.pipe(
      map(data => {
        const tickets = new Map<string, Array<GenericTicket>>();
        const comparator = new ShipmentTicketComparator(this.selectedTickets);

        // return a boothid-tickets map with tickets as an ordered array
        Array.from(data.keys()).forEach(id => {
          const ordered = Array.from(data.get(id).values()).sort((t1, t2) =>
            comparator.compare(t1, t2),
          );

          tickets.set(id, ordered);
        });
        return tickets;
      }),
    );
  }

  getSelectedBooths(): Observable<Array<ShipmentBooth>> {
    return this.selectedBoothsSubject.asObservable();
  }

  sendRequest(price: number): Observable<any> {
    return this.applyRequirementsToShip(price).pipe(
      switchMap(canPlay => {
        if (!canPlay) {
          return of(false);
        }
        return this.performRequest();
      }),
    );
  }

  performRequest(): Observable<any> {
    return (
      this.form.get('ship').value
        ? this.ticketShippingDao.requestShipment(this.form.toBackend())
        : this.ticketShippingDao.requestCollection(this.form.toBackend())
    ).pipe(
      tap((res: {message: string}) => {
        let message = res.message;
        const routeMap = environment.locale.routes;
        let navigate;

        if (this.responsiveService.isDesktop()) {
          const toRoute = this.form.get('ship').value
            ? routeMap.desktop.shipments.shipComplete
            : routeMap.desktop.shipments.collectionComplete;
          const ticketId = this.form.get('ticketsByBooth').value[0].tickets[0];
          const collectionPath = routeMap.tickets.collection;
          navigate = this.router.navigate([
            `${routeMap.desktop.ticket}/${ticketId}/${collectionPath}/${toRoute}`,
          ]);
        } else {
          navigate = this.router.navigate([`/m/${routeMap.mobile.main.tickets}`]);
        }

        navigate.then(() => {
          this.alertService.notifySuccess(message);
          if (this.responsiveService.isMobile()) {
            this.clearService();
          }
        });
      }),
      catchError(error => {
        if (this.isPlayErrorLimit(error.error)) {
          this.handlePlayErrorLimit(error);
        } else {
          this.errorService.processErrorGlobalContext(error, {
            key: 'localDeliveryInfo.errorGeneric',
          });
        }
        throw error;
      }),
      switchMap(() => this.privateInfoService.update()),
    );
  }

  requestShipmentPrice(
    boothId: string,
    state: string,
    hiddenDisableShippmentError: boolean,
    disableShippmentErrorMessageTextKey: string,
  ): Observable<number> {
    return this.sessionService.isLoggedIn().pipe(
      first(),
      switchMap(isLogged => {
        if (isLogged) {
          return this.ticketShippingDao.getShipmentPriceUser(boothId, state);
        } else {
          return this.ticketShippingDao.getShipmentPrice(boothId, state);
        }
      }),
      map((data: {price: number}) => data.price),
      catchError(err => {
        const error: TuLoteroServerError = err.error;
        if (this.errorIsShipmentDisabled(error)) {
          this.errorShippmentDisabled = error.message;
          if (!hiddenDisableShippmentError) {
            this.showDialogShippmentDisabled(
              error,
              disableShippmentErrorMessageTextKey,
            );
            this.errorService.processErrorManually(error);
          }
        } else {
          this.errorService.processErrorGlobalContext(error, {
            key: 'localDeliveryInfo.errorCost',
          });
        }
        return of(0);
      }),
    );
  }

  get shipmentAddress(): Observable<ShipmentAddress> {
    return this.sessionService.isLoggedIn().pipe(
      first(),
      switchMap((isLoggedIn: boolean) => {
        return isLoggedIn
          ? this.shipmentAddressDao.getShipmentAddress().pipe(first())
          : EMPTY;
      }),
    );
  }

  fillShipmentAdressForForm(form: ShippingAddressFormGroup) {
    this.shipmentAddress
      .pipe(
        switchMap((address: ShipmentAddress) => {
          if (!!address) {
            return of(address).pipe(
              map(shipmentAddress => form.fillAddress(shipmentAddress)),
            );
          } else {
            return this.userService.getData().pipe(
              filter(user => !!user),
              first(),
              map(user => form.fillAddressFromUser(user)),
            );
          }
        }),
      )
      .subscribe();
  }

  private errorIsShipmentDisabled(error: TuLoteroServerError): boolean {
    // Mejorar en un futuro cuando backend añada un id de error especifico
    return error.message.includes('Ceuta') || error.message.includes('Melilla');
  }

  private showDialogShippmentDisabled(
    error: TuLoteroServerError,
    message: string,
  ): void {
    if (!this.isDesktop) {
      this.modalHelperService.openOkCancelModal(ModalDialogComponent, {
        componentParams: {
          type: 'ok',
          title: error.message,
          message: !!message ? {key: message} : undefined,
          accept: {
            key: 'localDeliveryInfo.shippingNotAvailableForAddressAlert.understood',
          },
        },
      });
    } else {
      this.alertService.notifyError(error.message);
    }
  }

  private applyRequirementsToShip(price: number): Observable<boolean> {
    return this.userService.hasBalanceToBuyOrAutoCredit(price).pipe(
      map(hasBalance => {
        if (this.form.get('ship').value && !hasBalance) {
          this.taskManager.scheduleNewTask(
            TaskContext.LOAD_COMPLETE,
            'processShipment',
            () => this.sendRequest(price),
          );
          if (this.responsiveService.isDesktop()) {
            this.launchLoadMoneyOnDesktop(price);
          } else {
            this.launchLoadMoneyOnMobile(price);
          }

          return false;
        }

        return true;
      }),
    );
  }

  private notifyUpdates(): void {
    this.ticketsByBoothIdSubject.next(this.ticketsByBoothId);
    this.selectedBoothsSubject.next(this.selectedBooths);
  }

  private addAllTicketsForAdmin(
    boothId: string,
    includeCollectionRequested = false,
  ): boolean {
    this.allLoadedTickets
      .filter(
        ticket =>
          ticket.isActive() &&
          ticket.bet.boothId === boothId &&
          (ticket.shipmentInfo.isDeliverable() ||
            (includeCollectionRequested &&
              ticket.shipmentInfo.isCollectionRequested())),
      )
      .forEach(ticket => this.addTicket(ticket, false));
    this.ticketsByBoothIdSubject.next(this.ticketsByBoothId);
    return true;
  }

  private getDefaultLoadPageDesktop(endpoint: Endpoint): string {
    const routeMap = environment.locale.routes;
    return endpoint.paymentMethods && endpoint.paymentMethods.length > 0
      ? `/${routeMap.desktop.balance}/${routeMap.desktop.money.load}/`
      : `/${routeMap.desktop.balance}/${routeMap.desktop.money.card}/`;
  }

  private isPlayErrorLimit(error: any): boolean {
    return error && error.status === 'SELF_LIMIT_EXCEEDED';
  }

  private handlePlayErrorLimit(error: any): void {
    this.modalHelperService.openOkModal(LimitReachedModalComponent, {
      modalOptions: {centered: true},
      componentParams: {
        error: error.error,
        context: 'shipment',
      },
    });
  }

  private launchLoadMoneyOnDesktop(price: number) {
    this.endpointService
      .getData()
      .pipe(first())
      .subscribe((endpoint: Endpoint) => {
        this.router
          .navigate([this.getDefaultLoadPageDesktop(endpoint)], {
            queryParams: {
              [environment.locale.routes.money.priceParam]: price,
            },
          })
          .then(() =>
            this.alertService.notifyInfo({
              key: 'localDeliveryInfo.errorBalance',
            }),
          );
      });
  }

  private launchLoadMoneyOnMobile(price: number): void {
    const ticketId = this.form.get('ticketsByBooth').value[0].tickets[0];
    this.storableContextService.put(StorableContextEnum.LOAD_MONEY_SHIPMENT, <
      StorableContext<LoadBalanceGroupStorableContext>
    >{
      name: StorableContextEnum.LOAD_MONEY_SHIPMENT,
      data: {
        ticket: ticketId,
      },
    });

    this.moneyUtilsService.openMoneyChargeDialog().subscribe(() => {
      const routeMap = environment.locale.routes;
      this.router
        .navigate([`/m/${routeMap.mobile.load}`], {
          queryParams: {
            [environment.locale.routes.money.priceParam]: price,
          },
        })
        .then();
    });
  }
}
