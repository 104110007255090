import {BigPrizeButtonMetadata} from './big-prize-button-metadata';
import {TranslatableText} from '../i18n/translatable-text';

export enum BigPrizeType {
  DEFAULT,
  PT_MEDIUM,
  MEDIUM,
}

export interface BigPrize {
  prize: number;

  type: BigPrizeType;

  subtitle?: string;

  // Indicates that all money bets are divided among those with winning tickets.
  // Example: Gopher 5, North 5
  parimutuel: boolean;

  legendLabel: TranslatableText;

  buttons: Array<BigPrizeButtonMetadata>;
}
