import {Injectable} from '@angular/core';
import {
  AbstractObservableDataService,
  PageableService,
  PaginationData,
} from 'common';
import {Observable, Observer} from 'rxjs';
import {first} from 'rxjs/operators';

import {FilteredDataService} from '../../../common/list/filtered-data-service';
import {Filter} from '../../game-filter/data/filter';

import {GenericResult} from './generic-result';
import {ResultsDao} from './results.dao';
import {ResultsService} from './results.service';

@Injectable({providedIn: 'root'})
export class ResultsListService
  extends AbstractObservableDataService<Array<GenericResult>>
  implements
    FilteredDataService<GenericResult>,
    PageableService<Array<GenericResult>>
{
  paginationIndex = 0;

  paginationEnd = false;

  filter: Filter;

  constructor(
    protected resultsDao: ResultsDao,
    protected resultsService: ResultsService,
  ) {
    super();
    this.onConstructActions();
    this.resultsService.getData().subscribe(data => {
      this._data.next(data);
    });
  }

  loadMore(): Observable<Array<GenericResult>> {
    return new Observable((observer: Observer<Array<GenericResult>>) => {
      this.moreResults().subscribe(results => {
        this._data.pipe(first()).subscribe({
          next: current => {
            const currentPaginationIndex = this.paginationIndex;
            this.paginationIndex += results.data.length;
            let newData: Array<GenericResult>;

            if (currentPaginationIndex > 0) {
              newData = current.concat(results.data);
            } else {
              newData = results.data;
            }
            this._data.next(newData);

            if (this.paginationIndex >= results.total || results.data.length === 0) {
              this.paginationEnd = true;
            }
            observer.next(newData);
            observer.complete();
          },
          error: e => observer.error(e),
        });
      });
    });
  }

  reset(): Observable<Array<GenericResult>> {
    this.paginationEnd = false;
    this.paginationIndex = 0;

    return this.loadMore();
  }

  protected moreResults(): Observable<PaginationData<GenericResult>> {
    return this.resultsDao.moreResults(
      this.paginationIndex,
      this.filter ? this.filter.gameIds : null,
    );
  }

  protected onConstructActions(): void {
    this._data.subscribe(r => (this.paginationIndex = r.length));
  }
}
