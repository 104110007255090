import {Inject, Injectable} from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {from, mapTo, Observable, throwError} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {ModalDialogComponent, ModalHelperService, ROUTES} from 'common';
import {PICKUP_SIGNATURE_PENDING} from './pickup-signature-pending-status';
import {Router} from '@angular/router';

@Injectable()
export class RequestMediumPrizeSignatureInterceptor implements HttpInterceptor {
  constructor(
    private modalHelperService: ModalHelperService,
    private router: Router,
    @Inject(ROUTES) private routes: Record<string, any>,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(error => {
        if (error?.error.status === PICKUP_SIGNATURE_PENDING) {
          return this.launchPendingDocumentModal();
        } else {
          return throwError(() => error);
        }
      }),
    );
  }

  private launchPendingDocumentModal(): Observable<any> {
    return this.modalHelperService
      .openOkModal(ModalDialogComponent, {
        componentParams: {
          type: 'ok_only',
          title: {
            key: 'middlePrize.officialTicketRequest.documentSignPedingDialog.title',
          },
          image: 'assets/img/medium-prizes/img-doc-pin.svg',
          imageWidth: 96,
          accept: {
            key: 'middlePrize.officialTicketRequest.documentSignPedingDialog.okButton',
          },
        },
      })
      .pipe(switchMap(() => this.navigateToSignaturePage()));
  }

  private navigateToSignaturePage(): Observable<void> {
    return from(
      this.router.navigate([
        '/m',
        ...this.routes.mobile.requestMediumPrize.split('/'),
        841647, // TODO - replace with actual id
        this.routes.mobile.requestMediumPrizeCollectionReceipt,
      ]),
    ).pipe(mapTo(void 0));
  }
}
