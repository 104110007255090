import * as deepmerge from 'deepmerge';

import {ENVIRONMENT_DEV} from './global/dev';
import {ENVIRONMENT_ES} from './local/es';

export const environment = deepmerge.all<any>([
  {},
  ENVIRONMENT_DEV,
  ENVIRONMENT_ES,
  {
    angularProduction: true,
    mockTPVErrors: false,
    endpoint: 'https://web.es.pre.tulotero.net/tuloteroweb/rest',
    endpointCommon: {
      ALL: 'https://static.es.stage.tulotero.net/allinfo.json',
      es_ES: 'https://static.es.stage.tulotero.net/allinfo.json',
    },
    endpointTicketImage: 'https://web.es.pre.tulotero.net',
    useGoogleSDK: false,
    serviceWorkerEnabled: true,
    features: {
      showHideClubs: true,
    },
    appleRedirectUri: 'https://web.es.pre.tulotero.net',
    noDeleteAllInfoInVersionURL:
      'https://static.stage.tulotero.net/versions-web-pre.json',
  },
]);
