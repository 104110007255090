import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, zip} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {ResponsiveService} from 'common';
import {environment} from '~environments/environment';

import {AbstractDao} from '../../common/model/abstract.dao';
import {CardData} from '../data/card-data';
import {KycCardFormGroup} from './kyc-card-form-group';
import {KycSelfieFormGroup} from './kyc-selfie-form-group';

@Injectable({providedIn: 'root'})
export class KycDao extends AbstractDao {
  constructor(
    private http: HttpClient,
    private responsiveService: ResponsiveService,
  ) {
    super();
  }

  sendKyc(
    method: string,
    id: string,
    data: Array<KycSelfieFormGroup | KycCardFormGroup>,
  ): Observable<void> {
    return zip(
      data.reduce(
        (fields, item) => [
          ...fields,
          ...item.prepareImage(
            environment.kyc.resizeImageHeight,
            environment.kyc.compressImage,
            this.responsiveService.isDesktop(),
          ),
        ],
        [],
      ),
    ).pipe(
      map(arrayDataPrepared =>
        arrayDataPrepared.reduce((dataJoin, item) => ({...dataJoin, ...item}), {}),
      ),
      switchMap((dataPrepared: Record<string, any>) => {
        let body = new FormData();

        Object.keys(dataPrepared).forEach(idx =>
          body.append(idx, dataPrepared[idx]),
        );

        return this.http.post<void>(
          `${this.baseUrl}/users/kyc/servlet/${method}?id=${id}`,
          body,
        );
      }),
    );
  }

  checkCardCode(data: any): Observable<CardData> {
    return this.http
      .post(`${this.baseUrl}/users/kyc/scanner/parse`, data)
      .pipe(
        map((r: any) =>
          r.message === 'OK' ? CardData.createFromBackend(r.object) : r,
        ),
      );
  }
}
